@import "styles/common.module.scss";

.container {
	border: 1px solid #cccccc;
	padding: 15px;
	background: #fff;
	margin: 50px 15px;
	@media #{$viewportMedium} {
		width: 605px;
		padding: 96px;
		margin: 50px auto;
	}
}

.form {
	display: flex;
	flex-direction: column;
}

.title {
	font-size: 24px;
	line-height: 42px;
	font-weight: 400;
}

.passwordRow {
	display: flex;
	justify-content: space-between;

	& input {
		width: 100%;
	}

	& > div {
		width: calc(50% - 12px);
	}
}

.sessionNote {
	color: #1da1f2;
	font-size: 14px;
	font-weight: 400;
	margin-bottom: 20px;

	&:hover {
		cursor: pointer;
		text-decoration: underline;
	}
}

.submitButton {
	composes: button;
	background: $red;
	color: #fff;
	border: none;
	padding: 0 45px;
	cursor: pointer;

	&:disabled {
		opacity: 0.8;
	}
}

.buttonsRow {
	display: flex;
	justify-content: space-between;
}
