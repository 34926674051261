.companyLogo {
	height: 48px;
	min-height: 48px;
	width: 48px;
	min-width: 48px;
	overflow: hidden;
	border-radius: 100px;
	border: 1px solid #dedede;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	img {
		width: 85%;
		height: 85%;
		object-fit: contain;
	}
}

.companyLogo.uploadable:hover {
	cursor: pointer;

	img {
		width: 100%;
		height: 100%;
		content: url("../../assets/images/upload-logo.svg");
	}
}
