@import "../variables.module";
@import "variables";
@import "responsive";

.reports-modal,
.modal {
	padding: 40px;
	width: 995px;
	max-height: 90vh;

	@media #{$viewportMobile} {
		width: 95vw;
	}
	@media #{$viewportMobileS} {
		width: 95vw;
	}
	@media #{$viewportTablet} {
		width: 95vw;
	}

	.modal-title {
		font-size: 16px;
		line-height: 26px;
		font-weight: 500;
		border-bottom: 1px solid $borderColorPattern2;
		padding-bottom: 16px;
		margin-bottom: 32px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.close-icon {
		top: 19px;
		right: 18px;

		span {
			background: #ffffff;
		}
	}

	.company-logo-column {
		text-align: left;
		img {
			width: 50px;
			object-fit: cover;
			background: black;
		}
	}

	.buttons {
		margin-top: 24px;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;

		button {
			margin-left: 23px;
			font-weight: 400;
			font-size: 14px;
			line-height: 24px;
		}
	}

	.custom-input {
		padding-bottom: 0;
	}

	.col {
		margin-bottom: 16px;
	}

	.input {
		padding-bottom: 0;

		input {
			border-color: $borderColorPattern3;
			border: 1px solid $borderColorPattern3 !important;
			border-radius: 0;
		}
	}

	.role-form-wrapper {
		border-bottom: 1px solid $borderColorPattern1;
		margin: 15px 0;
		padding-bottom: 15px;

		.role-row {
			display: flex;
			flex-direction: column;
		}
	}

	.date-row {
		display: flex;
		flex-direction: row;
		width: 100%;
		gap: 30px;

		.calender-icon {
			position: relative;
			top: 10px;
			left: 10px;
			z-index: 1;
		}

		.date {
			flex-grow: 1;

			.datepicker-input {
				top: -19px;

				input {
					padding-left: 45px;
				}
			}

			.DayPickerInput {
				width: 100%;
				position: relative;
				top: -20px;

				input {
					width: 100%;
					padding-left: 45px;
				}
			}
		}
	}

	label {
		font-weight: 500 !important;
	}

	.DayPicker {
		.DayPicker-wrapper {
			padding: 10px;
		}

		.DayPicker-NavButton {
			top: 11px;
			right: 5px;
		}

		.DayPicker-Weekday {
			font-size: 11px;
		}

		.DayPicker-Day {
			font-size: 12px;
			height: 30px;
			width: 30px;
		}

		.DayPicker-Day--disabled {
			color: $grayAlt !important;
			background-color: $borderColorPattern2 !important;
		}
	}

	.establishment-label {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}

	.action-message {
		font-family: "Calibri", sans-serif;
		font-style: normal;
		font-weight: 300;
		font-size: 14px;
		line-height: 20px;
	}
}

.experience-modal,
.education-modal,
.candidate-profile-modal {
	max-height: 90vh;
}

.fit-to-brief-modal {
	.input-wrapper {
		position: relative;

		label {
			position: absolute;
			top: 24px;
			left: 24px;
			font-weight: 400;
		}

		textarea {
			padding-top: 46px;
		}
	}
}

.remuneration-modal {
	.buttons {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: space-between;

		.action-buttons {
			display: flex;
			flex-direction: row;
		}
	}

	.section-row {
		display: flex;
		flex-direction: column;
		gap: 12px;
		margin-bottom: 16px;

		&:last-child {
			margin-bottom: 0;
		}

		label {
			margin-bottom: 0 !important;
		}

		.row {
			display: flex;
			flex-direction: row;
			gap: 16px;

			.col {
				display: flex;
				flex-grow: 1;

				.custom-input {
					width: 100%;

					input {
						border-color: #dedede;
					}
				}

				.dropdown {
					display: flex;
					width: 100%;
				}

				&.currency {
					width: 100px;
					max-width: 100px;
				}
			}
		}
	}
}

.DayPickerInput-OverlayWrapper {
	//z-index: 99;
	position: relative !important;

	.DayPickerInput-Overlay {
		position: relative !important;
	}
}

.reports-modal,
.form {
	padding: 27px 24px 33px 26px;

	.modal-title {
		border-bottom: none;
		margin-bottom: 23px;
	}

	label {
		font-weight: 400;
	}

	.invalid-input {
		position: relative;

		// label {
		// 	padding-bottom: 42px !important;
		// }

		input {
			border: 1px solid $errorColor !important;
			background: #ffffff !important;
		}

		.error-icon {
			display: none !important;
		}
	}

	.validation-error {
		display: flex;
		width: 100%;
		height: 28px;
		background: $errorColor;
		color: #ffffff;
		border-radius: 3px;
		padding: 4px 8px;
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		position: relative;

		&:before {
			content: "";
			display: block;
			width: 0;
			height: 0;
			border-left: 8px solid transparent;
			border-right: 8px solid transparent;
			border-bottom: 8px solid $errorColor;
			position: absolute;
			top: -5px;
			left: 2px;
		}
	}
}
