@import "styles/variables.module.scss";
@import "styles/Report/_mixins.scss";

.errorText {
	top: 26px;
	width: 100%;
	height: 28px;
	background: $errorColor;
	color: #ffffff;
	border-radius: 3px;
	padding: 4px 8px;
	font-weight: 400;
	line-height: 20px;
	font-size: 13px;
	position: absolute;
	bottom: 10px;

	&:before {
		content: "";
		display: block;
		width: 0;
		height: 0;
		border-left: 8px solid transparent;
		border-right: 8px solid transparent;
		border-top: 8px solid $errorColor;
		position: absolute;
		bottom: -5px;
		left: 2px;
	}
}
