.candidate-profile-page {
	overflow: auto;

	.container {
		@include containerPadding();
	}

	.filter-options {
		margin: 32px 0 8px 0;
		display: flex;
		flex-direction: column;

		@include breakpoint(gtmd) {
			margin: 60px 0 24px 0;
			align-items: center;
			justify-content: space-between;
			flex-direction: row;
		}

		.left-block-filters {
			display: flex;
			align-items: center;
			justify-items: center;
		}

		.right-block-filters {
			align-items: center;
			display: flex;

			.btn {
				@include font14-bold();
				@include breakpoint(gtmd) {
					margin-left: 20px;
				}
			}

			@include breakpoint(xs) {
				flex-direction: column;
				gap: 16px;

				.btn {
					width: 100%;
					margin-left: 0;
				}
			}
		}
	}

	.browse-candidate {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.btn {
			font-size: 14px;
			line-height: 24px;
			padding: 8px 16px;

			svg {
				margin: 0;
			}

			@include breakpoint(xs) {
				svg {
					margin: 0 !important;
				}

				label {
					display: none;
				}
			}
		}

		.left {
			.btn svg {
				margin-right: 15px;
			}
		}

		.right {
			.btn svg {
				margin-left: 15px;
			}
		}

		.center {
			font-size: 16px;
			font-weight: 500;
			line-height: 26px;
			color: $gray;

			@include breakpoint(xs) {
				font-size: 14px;
				color: #141414;
			}
		}
	}

	.candidate-card {
		background: #fbfcfd;
	}

	.details-information {
		display: flex;
		flex-direction: row;
		background: #ffffff;
		padding: 20px 30px;

		@include breakpoint(xs) {
			flex-direction: column;
			padding: 16px;
		}

		.section-title {
			@include section-title-light;
		}

		.experiences-section {
			display: flex;
			flex-direction: column;
			margin-bottom: 30px;

			.experience {
				display: flex;
				justify-content: flex-start;
				margin-top: 16px;

				.avatar {
					margin-right: 16px;

					img {
						height: 32px;
					}
				}

				.info {
					display: flex;
					flex-direction: column;
					flex-grow: 1;

					.role {
						margin-bottom: 8px;
						display: flex;
						justify-content: space-between;
					}

					.item {
						margin-bottom: 3px;
					}

					.title-with-dot {
						display: flex;
						align-items: flex-start;

						.dot {
							margin-top: 5px;
							width: 9px;
							min-width: 9px;
							height: 9px;
							background: #dedede;
						}
					}
				}
			}
		}

		.left-column {
			display: flex;
			flex-direction: column;
			width: 300px;
			min-width: 300px;
			max-width: 300px;
			flex-basis: auto;
			flex-grow: 1;
			margin-right: 30px;
			@include font14-light(400);
			color: $black;

			@include breakpoint(xs) {
				margin-right: 0;
				width: 100%;
				min-width: 100%;
				max-width: 100%;
			}

			.education-section {
				display: flex;
				flex-direction: column;
				margin-bottom: 30px;

				.education {
					display: flex;
					flex-direction: row;
					margin-top: 16px;

					.avatar {
						width: 40px;
						min-width: 40px;
						margin-right: 8px;
						height: 100%;
						display: flex;
						align-items: center;

						img {
							width: auto;
							max-width: 40px;
						}
					}

					.info {
						display: flex;
						flex-direction: column;
						flex: 1;

						.institute-name {
							display: flex;
							justify-content: space-between;
						}
					}
				}
			}

			.remuneration-section {
				margin-bottom: 30px;

				.section-title {
					margin-bottom: 16px;
				}

				.info {
					display: flex;
					flex-direction: row;
					font-size: 12px;
					line-height: 18px;
					color: $black;
					margin-bottom: 6px;

					.label {
						width: 100px;
						min-width: 100px;
						margin-right: 18px;
						color: #141414;
					}

					.values {
						display: flex;
						flex-direction: column;
						flex-wrap: wrap;
					}
				}
			}

			.notice-section {
				.info {
					margin-top: 10px;
					font-size: 12px;
					line-height: 18px;
					color: $black;
				}
			}
		}

		.right-column {
			display: flex;
			flex-direction: column;
			width: 100%;

			@include breakpoint(xs) {
				.summary-title {
					margin-top: 16px;
				}
			}

			.summary-title {
				display: flex;
				justify-content: space-between;

				.edit-icon {
					margin-right: 0;
					margin-top: 5px;
				}
			}

			.list-info {
				max-width: 770px;

				p {
					margin: 0;
				}
			}

			.section-summary {
				margin: 15px 0 0 0;
			}

			.fit-to-brief-section {
				margin-top: 30px;

				.section-title {
					padding-bottom: 16px;
					margin-bottom: 16px;
					border-bottom: 1px solid $borderColorPattern2;

					@include breakpoint(xs) {
						margin: 0 -16px;
						padding: 0 16px 8px;
					}
				}
			}
		}
	}
}

.briefs-wrapper {
	.brief-item {
		display: flex;
		flex-direction: row;
		width: 100%;

		@include breakpoint(xs) {
			margin: 0 -16px;
			padding: 0 16px;
			border-bottom: 1px solid $borderColorPattern2;
		}

		.info-area {
			width: 100%;
			display: flex;
			flex-direction: column;

			.title {
				margin-top: 12px;
				font-size: 16px;
				line-height: 26px;
				margin-bottom: 12px;
				font-weight: 400;
				display: flex;
				flex-direction: row;
				justify-content: space-between;
			}

			.notes {
				@include breakpoint(gtxs) {
					margin-right: 116px;
				}

				.item {
					display: flex;
					align-items: baseline;
					color: #141414;
					margin-bottom: 12px;
					@include font14-light(400);

					.dot {
						background: $blackYellow;
						min-width: 10px;
						margin-right: 6px;
					}
				}
			}
		}
	}
}

.tenure {
	color: #141414;
	font-size: 12px;
	line-height: 18px;
}

.level {
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	font-size: 14px;
	line-height: 24px;
	font-weight: 400;

	.square-wrapper {
		display: flex;
		flex-direction: row;
	}

	.custom-tooltip {
		margin-top: -10px !important;
	}

	.square {
		margin-bottom: 4px;
		margin-right: 4px;
		height: 20px;
		width: 20px;
		background: rgba(205, 206, 207, 0.4);

		&:last-child {
			margin-right: 0;
		}

		&.fill {
			background: #bd281e;
		}
	}
}

.assessment {
	margin-top: 30px;

	.section-title {
		margin-bottom: 36px;
	}

	.collaborator-wrapper {
		display: flex;
		flex-direction: row;
		gap: 20px;
	}

	.item {
		display: flex;
		flex-direction: row;
		gap: 12px;
		font-size: 14px;
		line-height: 18px;

		.sub-title {
			font-size: 12px;
			color: #141414;
		}

		.short-desc {
			margin-top: 7px;
			line-height: 20px;
			font-weight: 300;
			color: #141414;
		}
	}
}

.assessment-wrapper {
	margin-top: 30px;
	display: flex;
	flex-direction: column;
	gap: 30px;
	font-size: 14px;
	line-height: 20px;
	font-weight: 300;
	color: #141414;

	.item {
		display: flex;
		flex-direction: row;
		gap: 20px;
		align-items: center;
		text-align: center;

		.note:nth-child(2) {
			order: 1;
		}

		.note {
			max-width: 292px;

			&.level {
				font-size: 12px;
				line-height: 18px;
				justify-content: center;
				align-items: center;
				gap: 2px;
			}
		}
	}
}

.areas-to-explore {
	margin-top: 30px;
	display: flex;
	flex-direction: column;

	.section-title {
		margin-bottom: 12px;
	}
}

.list-info {
	@include font14-light(400);
	color: #141414;
	margin-bottom: 12px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: baseline;
	max-width: 770px;

	.dot {
		background: $blackYellow;
		margin-right: 6px;
		min-width: 10px;
	}

	h1,
	h2 {
		font-size: 16px;
		line-height: 26px;
		font-weight: 400;
		color: #141414;
	}

	ul {
		margin: 0;
		padding-left: 0;
		display: block;
		border: none !important;

		li {
			display: block;
			position: relative;
			margin-bottom: 12px;

			&::before {
				position: relative;
				content: "\2022";
				font-size: 26px;
				color: $blackYellow;
				vertical-align: middle;
				left: -5px;
				top: 1px;
			}
		}
	}
}

.line-with-label {
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	margin-top: 2px;

	.line {
		width: 14px;
		height: 22px;
		margin-left: 4px;
		border-left: 1px solid #dedede;
	}
}

.edit-candidate-form {
	.row {
		.col {
			flex-basis: 5em;

			&.input-wrapper {
				flex-basis: 50em;

				textarea {
					font-size: 14px;
					line-height: 20px;
					font-weight: 400;
					color: #141414;
				}
			}
		}
	}
}
