.page-header,
.page-header-space {
	height: 72px;
	z-index: 88;
}

.page-footer,
.page-footer-space {
	height: 50px;
	z-index: 88;
}

.page-footer {
	position: fixed;
	bottom: 0;
	width: 210mm;
}

.page-header {
	position: fixed;
	top: 0;
	width: 210mm;
}

.page-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	//width: 100%;
	//height: 72px;
	padding: 16px 80px;
	background: #373937;

	.logo {
		position: relative;
	}

	.information-block {
		display: flex;
		gap: 16px;
		font-style: normal;
		font-weight: 400;
		font-size: 14px;
		line-height: 24px;
		text-align: right;
		color: #dedede;
		text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
		flex: none;
		order: 0;
		flex-grow: 0;
	}
}

.page {
	page-break-after: always;
	padding: 0 80px;
}

@page {
	margin: 0;
}

@media print {
	* {
		-webkit-print-color-adjust: exact !important; /* Chrome, Safari, Edge */
		color-adjust: exact !important; /*Firefox*/
		padding: 0;
		margin: 0;
	}

	table {
		page-break-after: auto;
	}
	table tr {
		page-break-inside: avoid;
		page-break-after: auto;
	}
	table td {
		page-break-inside: avoid;
		page-break-after: auto;
	}
	table thead {
		display: table-header-group;
		margin-top: 50px;
	}
	table tfoot {
		display: table-footer-group;
	}

	button {
		display: none;
	}

	body {
		margin: 0;
	}
}

.print-container {
	background: white;
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	box-shadow: 0 0 0.5px rgba(0, 0, 0, 0.5);
	min-height: 100vh;

	&[layout="portrait"] {
		width: 210mm;
	}

	table {
		margin: 0 80px;
		border-collapse: collapse;

		tr {
			border-top: 1pt solid #dedede;

			&:first-child {
				border-top: none;
			}
		}

		&.no-spacing {
			border-spacing: 0; /* Removes the cell spacing via CSS */
			border-collapse: collapse; /* Optional - if you don't want to have double border where cells touch */
		}
	}

	//.header-info {
	//	flex-direction: row !important;
	//	align-items: center !important;
	//
	//	.company .logo {
	//		margin-bottom: 0 !important;
	//	}
	//}
}
