@import "styles/variables.module.scss";

.radioContainer {
	display: flex;
}
.radio {
	display: inline-flex;
	height: 32px;
	&:not(:first-of-type) {
		margin-left: 4px;
	}
	&:not(:last-of-type) {
		margin-right: 4px;
	}
}
.radioInput {
	opacity: 0;
	z-index: -1;
	width: 0;
}

.radioLabel {
	height: 32px;
	display: inline-flex;
	background: #fff;
	color: $lightGray;
	border: 1px solid #d7dfe9;
	align-items: center;
	font-size: 14px;
	line-height: 16px;
	padding: 0 7px;
	white-space: nowrap;
}
.active {
	background: $red;
	color: #fff;
	border-color: $red;
}

.activeDark {
	background: #333;
	color: #fff;
	border-color: #333;
}

.radioButtonContainer {
	justify-content: space-between;
	display: flex;
	flex-wrap: wrap;
}
.radioButtonInput {
	composes: radioInput;
	&:checked + .radioButtonLabel {
		background: $red;
		color: #fff;
		border-color: $red;
	}
}

.radioButton {
	display: inline-flex;
	height: 40px;
	width: calc(50% - 10px);
	&:nth-of-type(3n) {
		width: 100%;
		margin-top: 16px;
	}
}

.radioButtonLabel {
	display: flex;
	align-items: center;
	justify-content: center;
	border: 1px solid #d7dfe9;
	width: 100%;
	cursor: pointer;
}

.fullRadioLabelButton {
	width: 100%;
}
