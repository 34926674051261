.section {
	min-height: 100vh;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 5%;
}

.left {
	text-align: center;
}

.right {
	margin-left: 37px;
	max-width: 528px;

	.title {
		margin: 0;
		font-size: 36px;
		font-weight: 500;
		color: #141414;
	}

	.description {
		margin: 16px 0 32px 0;
		font-size: 24px;
		color: #141414;
	}

	.link {
		display: inline-flex;
		align-items: center;
		color: #999999;
	}

	.link > svg {
		margin-right: 13px;
	}
}

@media (max-width: 800px) {
	.section {
		flex-direction: column;
	}

	.right {
		margin-left: 0;
		text-align: center;
	}
}

@media (max-width: 450px) {
	.left {
		svg {
			width: 90%;
		}
	}

	.right {
		width: 90%;

		.title {
			font-size: 28px;
		}

		.description {
			font-size: 18px;
		}
	}
}
