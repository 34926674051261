@import "styles/common.module.scss";

.companyLabel {
	background-color: rgba(255, 0, 0, 0.1);
	color: red;
}

.select {
	width: 300px;
	font-size: 0.9rem;

	@media #{$viewportMobileS} {
		width: 200px;
		font-size: 0.7rem;
	}
	@media #{$viewportMobile} {
		width: 72vw;
		margin-left: 0;
		font-size: 0.7rem;
	}
	@media #{$viewportSmall} {
		width: 300px;
	}
	@media #{$viewportMedium} {
		width: 300px;
	}
	@media #{$viewportTablet} {
		width: 340px;
	}
	@media #{$viewportLarge} {
		width: 500px;
	}
	@media #{$viewportMinLarge} {
		width: 600px;
	}
	@media #{$viewportLargeWithPaddings} {
		width: 700px;
	}
	@media #{$viewportLargeS} {
		width: 700px;
	}
	@media #{$viewportLargeSM} {
		width: 700px;
	}
	@media #{$viewportLargeSM} {
		width: 700px;
	}
	@media #{$viewportLargeX} {
		width: 700px;
	}
	@media #{$viewportXLarge} {
		width: 700px;
	}
}
