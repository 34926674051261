.assessment-page {
	$breakPoint: 550px;

	overflow: auto;

	.container {
		@include containerPadding();
	}

	.interview-schedule,
	.print-candidates {
		position: relative;

		@media (max-width: $breakPoint) {
			width: 100%;

			.btn {
				width: 100%;
			}
		}
	}

	.filter-options {
		display: flex;
		flex-direction: column;
		margin-top: 20px;

		@include breakpoint(gtsm) {
			justify-content: space-between;
			flex-direction: row;
		}

		.left-block-filters {
			display: flex;
			align-items: center;

			.filter-item-group {
				display: flex;
				flex-direction: row;

				@include breakpoint(xs) {
					width: 100%;
					gap: 17px;
				}
			}

			.btn {
				align-items: flex-end;
				@include font14-bold();
			}

			.filter-item {
				margin-right: 20px;

				&.active button {
					background: $darkRed !important;
					color: #ffffff;

					svg {
						path {
							fill: #ffffff;
						}
					}
				}
			}

			@include breakpoint(xs) {
				gap: 17px;
				flex-direction: column;

				.filter-item {
					margin-right: 0;
				}
			}
		}

		.right-block-filters {
			display: flex;
			align-items: center;
			margin-top: 20px;

			@include breakpoint(gtsm) {
				margin-top: 0;
			}

			@include breakpoint(xs) {
				flex-direction: column;
				align-items: flex-start;
				gap: 16px;

				.pin-item,
				.pin-item .btn {
					width: 100%;
				}
			}

			@include breakpoint(gtmd) {
				margin-top: 0;
			}

			.dropdown-block {
				margin-right: 0;
				display: flex;
				justify-content: flex-end;
				width: auto;
				@include font14-bold();

				//@include breakpoint(gtmd) {
				//  margin-right: 29px;
				//}

				@include breakpoint(gtmd) {
					width: 288px;
				}

				@include breakpoint(xs) {
					flex-direction: column;
					align-items: flex-start;
					gap: 6px;
					width: 100%;

					.dropdown {
						width: 100%;
					}
				}
			}

			//.btn {
			//  @include font14-bold();
			//}
		}
	}

	.tabs-with-info {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		border-bottom: 1px solid $borderColorPattern2;
		margin: 32px 0 20px;

		@include breakpoint(xs) {
			flex-direction: column-reverse;
			border-bottom: none;

			.tabs-wrapper {
				margin-top: 32px;
			}

			.info {
				margin-top: 0 !important;
				margin-bottom: 16px;
				font-size: 12px !important;
				line-height: 30px !important;
			}
		}

		.buttons {
			display: flex;
			flex-direction: row;
			gap: 16px;

			@include breakpoint(xs) {
				width: 100%;
				flex-direction: column;
			}

			span.warning {
				font-size: 12px;
				position: absolute;
				top: -20px;
				width: 112%;
				color: #702c2e;
			}
		}
	}
}

.candidate-card-line {
	width: 100%;
	height: 1px;
	border-bottom: 1px solid #dedede;
}

.assessment-page,
.candidate-profile-page {
	&.research-mode {
		.tabs-with-info {
			.info {
				margin-top: -16px;
				font-size: 16px;
				color: black;
				line-height: 30px;
				border-bottom: none;

				@include breakpoint(gtxs) {
					border-bottom: 1px solid $borderColorPattern2;
					min-width: 300px;
					padding-bottom: 20px;
				}
			}
		}

		.candidate-card {
			.avatar {
				.avatar-details {
					display: flex;
					flex-direction: row;
					align-items: center;
					gap: 10px;
					position: relative;
					justify-content: center;

					.uploadable:hover {
						cursor: pointer;
						content: url("../../assets/images/upload-logo.svg");
					}

					.edit-icon {
						position: absolute;
						right: -30px;
					}

					.icon-group {
						display: flex;
						min-width: 20px;
						flex-direction: column;
						justify-content: center;

						svg {
							display: flex;

							&:hover {
								cursor: pointer;
							}

							&:first-child {
								margin-bottom: 5px;
							}
						}
					}
				}
			}
		}
	}

	.candidates-card-wrapper {
		display: flex;
		flex-direction: column;
		margin-top: 24px;
		margin-bottom: 30px;

		.candidate-card {
			display: flex;
			flex-direction: row;
			height: auto;
			align-items: flex-start;
			background: #ffffff;
			border: 1px solid #cdcecf;
			margin-top: -2px; // necessary to hide duplicate top/bottom lines
			position: relative;
			z-index: 1;

			&.dragging {
				border-color: #ddc89a;
				z-index: 2;
				box-shadow: 0px 9px 16px rgba(0, 0, 0, 0.12);
			}

			&.inactive {
				// background: #fbfbfb;
				// border-bottom: 1px solid #f2f2f2;
			}

			@include breakpoint(xs) {
				flex-direction: column;
			}

			@include breakpoint(gtsm) {
				min-height: 118px;
			}

			.label {
				font-size: 14px;
				font-weight: 400;
				line-height: 18px;
				color: #999999;
				white-space: nowrap;
			}

			.avatar {
				flex-shrink: 0;
				background: #fbfcfd;
				width: 84px;
				align-self: stretch;
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				position: relative;

				@include breakpoint(gtxs) {
					width: 110px;
				}

				@include breakpoint(gtmd) {
					width: 128px;
				}

				@include breakpoint(xs) {
					$margin: 16px;
					width: calc(100% - #{$margin});
					align-items: flex-start;
					margin: 0 0 0 $margin;
					min-height: 48px;
				}

				.avatar-details {
					.name-wrapper {
						display: none;
					}

					.drag-handle {
						margin-left: -4px;
						cursor: grab;
						outline: none;

						&.disabled {
							cursor: not-allowed;
						}
					}

					@include breakpoint(xs) {
						display: flex;
						align-items: center;
						margin-top: 16px;

						.name-wrapper {
							display: block;
						}

						.value {
							margin-left: 16px;
						}
					}
				}

				.discussion {
					text-align: center;
					position: absolute;
					top: 0;
					width: 100%;
					background: rgba(112, 44, 46, 0.09);
					color: #bd281e !important;
					font-size: 13px;
					line-height: 16px;
					font-weight: 300;
					min-height: 24px;
					height: auto;
					display: flex;
					justify-content: center;
					align-items: center;

					@include breakpoint(xs) {
						position: absolute;
						right: 0;
						width: 100px;
					}
				}

				img {
					height: 56px;
					min-height: 56px;
					width: 56px;
					min-width: 56px;
					overflow: hidden;
					border-radius: 50%;
					object-fit: cover;

					@include breakpoint(xs) {
						height: 48px;
						min-height: 48px;
						width: 48px;
						min-width: 48px;
					}
				}
			}

			.info-block {
				display: flex;
				flex-direction: column;
				$margin: 16px;
				margin: $margin;
				width: calc(100% - #{$margin} * 2);

				@include breakpoint(gtxs) {
					width: 100%;
					margin: 16px 10px;
					flex-direction: row;
					justify-content: space-between;
				}

				@include breakpoint(gtsm) {
					margin: 16px 15px;
				}

				@include breakpoint(gtmd) {
					margin: 16px 20px;
				}

				.basic-info {
					display: flex;
					flex-direction: column;
					width: 100%;

					.view-button-wrapper {
						display: inline-flex;
						margin-top: 16px;
					}
				}

				.row {
					display: grid;
					grid-template-columns: repeat(5, 1fr);
					grid-template-rows: repeat(1, 1fr);
					gap: 8px;

					@include breakpoint(xs) {
						grid-template-columns: repeat(2, 1fr);
						gap: 16px;
					}

					.row-block {
						margin-bottom: 16px;

						> .info {
							margin-right: 16px;
						}
					}

					.btn {
						margin-right: 16px;
					}

					.value {
						font-size: 14px;
						line-height: 18px;
						color: #141414;
					}

					.info {
						display: flex;
						flex-direction: column;

						&.progress-status.xs-only {
							display: none;
						}

						&.name {
							@include breakpoint(xs) {
								display: none !important;
							}
						}

						&.role {
							// .value {
							// 	padding-top: 3px;
							// }
						}

						&.company {
							display: flex;
							flex-direction: row;
							justify-items: center;

							@include breakpoint(xs) {
								grid-column: 2;
								grid-row: 1;
							}

							// @include candidate-card-gap(187px);

							// .logo img {
							// 	height: 32px;
							// }

							.details {
								margin-left: 8px;
								//margin-right: 80px;
								display: flex;
								flex-direction: column;
							}
						}
					}

					.row-block {
						display: flex;
						flex-direction: row;
						width: 50%;

						@include breakpoint(gtsm) {
							width: auto;
						}

						@include breakpoint(xs) {
							width: 100%;
							min-width: 280px;
						}

						@include breakpoint(xxs) {
							flex-wrap: wrap;
							min-width: auto;
						}

						.progress-status.xs-only {
							@include breakpoint(gtxs) {
								display: none;
								width: auto;
							}
						}
					}
				}
			}
		}
	}

	@include breakpoint(xs) {
		.info-block {
			.row {
				display: flex;
			}

			.info {
				display: flex !important;
				align-items: flex-start !important;
				justify-items: flex-start !important;
			}

			.name,
			.company {
				.logo {
					display: none !important;
				}

				.details {
					margin-left: 0 !important;
				}
			}
		}

		.avatar {
			background: #ffffff !important;
		}
	}
}

.progress-block {
	display: flex;
	flex-direction: column;
	// width: 150px;

	.value {
		display: flex;
		font-size: 12px;
		line-height: 18px;
		font-weight: 400;
		color: #141414;

		&.date {
			color: #999999;
		}

		&:hover {
			cursor: pointer;
		}

		@include breakpoint(xs) {
			font-size: 14px;
			line-height: 26px;
		}
	}

	.dots {
		@include breakpoint(xs) {
			display: none;
		}

		display: flex;
		flex-direction: row;
		align-items: center;
		margin-top: 4px;
		margin-bottom: 9px;

		.dot-item-wrapper {
			display: flex;
			align-items: center;
		}

		.dot {
			height: 14px;
			width: 14px;
			margin-right: 0;
			background: $borderColorPattern3;

			&.active {
				background: $blackYellow;
			}
		}

		.line {
			width: 10px;
			height: 1px;
			border-bottom: 1px solid $borderColorPattern3;
			margin: 0 4px;

			&.active {
				border-bottom: 1px solid $blackYellow;
			}
		}
	}
}

.name-as-avatar {
	height: 56px;
	width: 56px;
	border-radius: 50%;
	background: #e7e7e7;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 20px;
	line-height: 16px;
	text-align: center;
	color: #141414;

	@include breakpoint(xs) {
		height: 48px;
		width: 48px;
	}
}
