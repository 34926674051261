.print-candidate-profile {
	font-family: "Calibri", sans-serif;

	.intro {
		display: flex;
		flex-direction: row;
		gap: 16px;

		.avatar {
			.name-as-avatar,
			img {
				height: 50px;
				width: 50px;
			}
		}

		.name {
			font-style: normal;
			font-weight: 400;
			font-size: 22px;
			line-height: 30px;
			display: flex;
			align-items: center;
			color: #141414;
		}
	}

	.highlighted {
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		color: #bd281e;
		background: #f2ecec;
		padding: 8px 16px;
		max-width: 113px;
		max-height: 40px;
	}

	tr {
		border-top: none !important;
	}

	.info {
		display: flex;
		align-items: flex-start !important;
		flex-direction: column !important;
		margin: 16px 5px 32px 0;

		.label {
			font-weight: 400;
			font-size: 14px;
			line-height: 26px;
			color: #999999;
		}

		.value {
			font-weight: 400;
			font-size: 14px;
			line-height: 22px;
			display: flex;
			align-items: center;
			color: #141414;
		}
	}

	.block-title {
		font-weight: 500;
		font-size: 18px;
		line-height: 26px;
	}

	.experience,
	.education {
		margin-top: 10px;
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		gap: 24px;

		.info {
			margin: 0 0 16px 0;
			display: flex;
			flex-direction: column;

			.role,
			.institute-name {
				font-weight: 400;
				font-size: 14px;
				line-height: 22px;
				margin-bottom: 8px;
			}
		}

		.title-with-dot {
			.dot {
				margin-right: 12px;
			}
		}
	}

	.package {
		margin: 10px 0;
		display: flex;

		.label {
			width: 70px;
			font-size: 14px;
			line-height: 22px;
		}
	}
}
