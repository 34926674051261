@import "./sanitize.module.scss";

@font-face {
	font-family: "Calibri";
	font-style: normal;
	font-weight: 300;
	src: local("Calibri"), url("../assets/fonts/CalibriLight.ttf") format("ttf");
}

@font-face {
	font-family: "Calibri";
	font-style: italic;
	font-weight: 300;
	src: local("Calibri"), url("../assets/fonts/CalibriLightItalic.ttf") format("ttf");
}

@font-face {
	font-family: "Calibri";
	font-style: normal;
	font-weight: 400;
	src: local("Calibri"), url("../assets/fonts/CalibriRegular.ttf") format("ttf");
}

@font-face {
	font-family: "Calibri";
	font-style: italic;
	font-weight: 500;
	src: local("Calibri"), url("../assets/fonts/CalibriItalic.ttf") format("ttf");
}

@font-face {
	font-family: "Calibri";
	font-style: normal;
	font-weight: 600;
	src: local("Calibri"), url("../assets/fonts/CalibriBold.ttf") format("ttf");
}

@font-face {
	font-family: "Calibri";
	font-style: italic;
	font-weight: 600;
	src: local("Calibri"), url("../assets/fonts/CalibriBoldItalic.ttf") format("ttf");
}

body,
html {
	margin: 0;
	//min-height: 100vh;
	height: 100%;
	font-size: 16px;
	background: #f5f5f5;
}

#root {
	height: 100%;
}

.fontHeight {
	height: 100%;
}

//body {
//	& > div {
//		display: flex;
//		flex-direction: column;
//		min-height: 100vh;
//	}
//}

main {
	display: flex;
	flex: 1;
	flex-direction: column;
	height: 100%;
}

.infinite-scroll-component {
	overflow: visible !important;
}
