@import "./variables.module.scss";

.containerMain {
	display: flex;
	flex-direction: column;
	max-width: $containerWidth;
	padding: 15px;
	margin: 0 auto;
}

.inline-flex-center {
	display: inline-flex;
	align-items: center;
	justify-content: center;
}

.flex-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

.flex-right {
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;
}

.align-right {
	display: flex;
	justify-content: flex-end;
}

.flex-column-center {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.button {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 40px;
	background: #ffffff;
	border: 1px solid #d7dfe9;
	box-sizing: border-box;
	font-size: 14px;
	line-height: 24px;
	padding: 0 25px;
	transition: all 0.1s;
}

.redButton {
	&:hover {
		background: $red;
		color: #fff;
	}
}

.grayButton {
	&:hover {
		background: $gray;
		color: #fff;
	}
}

.listNone {
	list-style: none;
	margin: 0;
	padding: 0;
}

.button-none {
	background: transparent;
	border: none;
	outline: none;
}

.p-none {
	margin: 0;
}
