$black: #373937;
$blackAlt: #141414;
$brandy: #ddc89a;
$lightGray: #f2f2f2;
$lightWhite: #f2f2f2;
$gray: #676767;
$grayAlt: #999999;
$red: #bd281e;
$darkRed: #702c2e;
$lightRed: rgb(238, 209, 209);
$borderColorPattern1: rgba(228, 228, 228, 0.4);
$borderColorPattern2: #f2f2f2;
$borderColorPattern3: #dedede;
$inactiveState: #dedede;
$lightOrange: #d8c396;
$orange: #f3a533;
$yellowOrange: #d8c396;
$blackYellow: #ddc89a;

$navbarHeight: 77px;
$containerPaddingTopBottom: 16px;
$pageTitlePadding: 16px;
$inputHeight: 40px;
$fontFamily: "Calibri", sans-serif;
