.checkbox-with-label {
	font-size: 14px;
	line-height: 20px;
	font-weight: 300;
	color: #141414;

	.custom-checkbox-label {
		margin: auto;
		-webkit-user-select: none;
		user-select: none;
		cursor: pointer;
		display: flex;
		align-items: center;

		span {
			display: inline-block;
			vertical-align: middle;
			transform: translate3d(0, 0, 0);
			font-weight: 400;

			&:first-child {
				position: relative;
				width: 16px;
				min-width: 16px;
				height: 16px;
				//border-radius: 3px;
				transform: scale(1);
				vertical-align: middle;
				border: 1px solid #dedede;
				transition: all 0.2s ease;

				svg {
					position: absolute;
					top: 2px;
					left: 1px;
					fill: none;
					stroke: #ffffff;
					stroke-width: 2;
					stroke-linecap: round;
					stroke-linejoin: round;
					stroke-dasharray: 16px;
					stroke-dashoffset: 16px;
					transition: all 0.3s ease;
					transition-delay: 0.1s;
					transform: translate3d(0, 0, 0);
				}

				&:before {
					content: "";
					width: 100%;
					height: 100%;
					background: $darkRed;
					display: block;
					transform: scale(0);
					opacity: 1;
					//border-radius: 50%;
				}
			}

			&:last-child {
				padding-left: 10px;
			}
		}

		&:hover span:first-child {
			border-color: $darkRed;
		}
	}

	.checkbox-custom:checked + .custom-checkbox-label span:first-child {
		background: $darkRed;
		border-color: $darkRed;
		animation: wave 0.4s ease;
	}

	.checkbox-custom:checked + .custom-checkbox-label span:first-child svg {
		stroke-dashoffset: 0;
	}

	.checkbox-custom:checked + .custom-checkbox-label span:first-child:before {
		transform: scale(3.5);
		opacity: 0;
		transition: all 0.6s ease;
	}

	@keyframes wave {
		50% {
			transform: scale(0.9);
		}
	}

	&.header-item {
		margin-bottom: 7px;
	}

	&.sub-item {
		padding: 5px 0 5px 27px;
	}
}
