.site-list {
	.options-as-list {
		left: 0;
		top: 12px;
		background: #ffffff !important;

		li {
			color: $grayAlt;
			font-size: 14px;
			font-weight: 400;

			&.active,
			&:hover {
				font-weight: 500;
				background: #ffffff !important;
				color: $blackAlt;
			}
		}
	}

	.icon-nav-bar {
		margin-right: 23px;
	}
}

.navbar-wrapper {
	background: $black;
	height: $navbarHeight;
	// background: #373937;
	position: fixed;
	z-index: 3;
	width: 100%;

	.container {
		.navigation-icon {
			display: none;
		}

		.logo-area {
			.logoWithText {
				@include breakpoint(xs) {
					height: 26px;
				}

				@include breakpoint(xxs) {
					display: none;
				}
			}

			.logoWithoutText {
				display: none;

				@include breakpoint(xxs) {
					display: inline-block;
				}
			}
		}

		@include breakpoint(xs) {
			padding: 23px 20px;
			flex-direction: row;
			min-height: 72px;

			.info-group {
				margin-left: 10px;

				@include breakpoint(xs) {
					&.researcher-mode {
						display: none;
					}
				}

				.custom-switch {
					margin-right: 0 !important;
					width: 30px;
					height: 20px;

					.switchBox {
						&:after {
							height: 17px;
						}
					}
				}

				.update-info {
					font-size: 12px !important;
					padding-right: 10px !important;

					.label {
						display: none;
					}
				}

				.notification {
					display: flex;
					padding: 10px 10px 10px 0 !important;
				}

				.profile {
					margin-left: 10px !important;
				}
			}

			.navigation-icon {
				&.researcher-mode {
					display: inline-flex !important;
				}
			}
		}
	}
}

.navbar {
	background: $black;
	// position: fixed;
	// z-index: 3;
	width: 100%;

	// @include breakpoint(gtxs) {
	// 	width: 1440px;
	// }

	.container {
		max-width: 100%;
		margin: 0;
		@include containerPadding(16px);

		.info-group {
			display: flex;
			align-items: center;
		}

		.update-info {
			//font-family: Futura, sans-serif;
			font-size: 14px;
			line-height: 24px;
			color: #cdcecf;
			padding-right: 16px;
			display: inline-flex;

			svg {
				&:hover {
					cursor: pointer;
				}
			}
		}

		.notification {
			padding: 10.5px 19.5px 10.5px 0;
			border-right: 1px solid $borderColorPattern1;

			&:hover {
				cursor: pointer;
			}
		}

		.profile {
			margin-left: 16px;
		}

		.avatar-on-nav {
			clip-path: circle();
		}
	}
}

.dropdown-custom {
	width: 150px;
}

.main-content-area {
	display: flex;
	justify-content: center;
	flex: 1;
	background: $gray;
	background-size: cover;
	padding-top: $navbarHeight;
	min-height: 100vh;

	.content-wrapper {
		width: 100%;
		background: #fbfbfb;

		.container {
			box-sizing: border-box;
			max-width: 1440px;
			margin: 0 auto;
			background: #fbfbfb;
			padding: $containerPaddingTopBottom 20px;

			@include breakpoint(gtxs) {
				padding: $containerPaddingTopBottom 25px;
			}

			@include breakpoint(gtsm) {
				padding: $containerPaddingTopBottom 50px;
			}

			@include breakpoint(gtmd) {
				padding: $containerPaddingTopBottom 75px;
			}

			@include breakpoint(gtlg) {
				padding: $containerPaddingTopBottom 100px;
			}

			@media (max-width: 350px) {
				padding: $containerPaddingTopBottom 16px;
			}

			.page-title {
				padding: $pageTitlePadding 0;

				@include section-title;

				display: flex;
				justify-content: space-between;
				align-items: center;

				.btn {
					padding: 7px 32px;
					font-size: 14px;
					line-height: 24px;
					font-weight: 500;
					margin-right: 10px;
				}
			}

			.content {
				display: flex;
				align-items: flex-start;
				justify-content: center;
			}
		}
	}

	.simple-breadcrumb {
		display: flex;
		align-items: center;
		padding: 0;

		> div {
			min-width: 105px;
		}

		.link:last-child {
			color: #676767;
		}

		.arrow {
			margin: 0 11px;
			display: flex;
			align-items: center;
		}

		@media screen and (max-width: #{$mobileBreakPoint}) {
			font-size: 14px;
		}

		@media screen and (max-width: 450px) {
			flex-wrap: wrap;
		}
	}
}

.info-with-progress {
	display: flex;
	flex-direction: column;
	width: 100%;

	.block {
		margin-bottom: 12px;

		.info-row {
			display: flex;
			justify-content: space-between;
			margin-bottom: 5px;

			.label {
				font-size: 14px;
				line-height: 24px;
				font-weight: 300;

				&.inactive {
					color: #999999;
				}
			}

			.value {
				font-size: 12px;
				line-height: 22px;
				font-weight: 500;

				&.inactive {
					color: $inactiveState;
				}
			}
		}

		.progress-bar {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			gap: 8px;

			progress {
				height: 18px;
				//background: $brandy;
				background-color: $lightWhite;
				border-radius: 0;

				&.researcher-mode {
					width: 85%;

					@include breakpoint(gtxs) {
						width: 290px;
					}
					@include breakpoint(gtmd) {
						width: 300px;
					}
				}

				&.client-mode {
					width: 92%;
				}

				&::-webkit-progress-bar {
					background-color: $lightWhite;
				}

				&::-webkit-progress-value {
					background: $brandy;
					height: 18px;
					border-radius: 0;
				}
			}

			&.inactive {
				progress {
					&::-webkit-progress-value {
						background: $inactiveState;
					}
				}
			}

			.value {
				font-size: 14px;
				font-weight: 500;
				line-height: 16px;
				//margin: 0 8px 0 8px;
				white-space: nowrap;

				&.inactive {
					color: $inactiveState;
				}
			}

			.eye-icon {
				width: 14px;
				display: flex;
				justify-content: center;
				align-items: flex-start;
			}
		}
	}
}

.adjust-spacing {
	padding-right: 6px;
	margin-right: -6px;
}

.custom-scrollbar::-webkit-scrollbar {
	width: 14px;
	background-color: transparent;
}

.custom-scrollbar::-webkit-scrollbar-track {
	border-radius: 4px;
	height: 10%;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
	border: 4px solid rgba(0, 0, 0, 0);
	background-clip: padding-box;
	border-radius: 9999px;
	background-color: $grayAlt;
}

.custom-switch {
	input:checked {
		& ~ .switchBox {
			background-color: $darkRed !important;
		}
	}
}

.password-hints {
	position: absolute;
	background: #fff;
	z-index: 99;
	padding: 16px;
	left: 0;
	bottom: 100px;
	border-radius: 4px;
	font-size: 14px;
	box-shadow: 12.5px 12.5px 10px rgba(0, 0, 0, 0.015),
		100px 100px 80px rgba(0, 0, 0, 0.03);

	&:after {
		content: "";
		position: absolute;
		width: 0;
		height: 0;
		border-width: 15px;
		border-style: solid;
		border-color: #ffffff transparent transparent transparent;
		bottom: -30px;
		// right: 160px;
	}

	.hint {
		display: flex;
		align-items: center;
	}

	> span {
		font-size: 16px;
		margin-bottom: 10px;
		display: block;
	}

	img {
		margin-right: 6px;
	}

	.valid {
		color: #00a651;

		.dot {
			background-color: #00a651;
		}
	}

	.invalid {
		color: #999999;
	}
}

.view-profile-button {
	> button {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 4px;
		background: #702c2e;
		border: 1px solid #702c2e;
		color: #fff;
		font-weight: 400;
		font-size: 14px;
		line-height: 16px;
		padding: 8px;
		cursor: pointer;
		width: 100%;
		height: 40px;

		& > svg path {
			fill: #fff;
		}
	}

	@include breakpoint(xs) {
		width: 100%;
	}
}

.linkedin-url {
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: #999999;
	border: none;
	background: transparent;
	display: flex;
	gap: 4px;
	cursor: pointer;
	padding: 0;
}
