.new-project-page {
	.page-title-with-buttons {
		display: flex;
		justify-content: space-between;
		margin: 40px 0 30px 0;

		.title {
			@include section-title;
		}

		.button-group {
			display: flex;
			flex-direction: row;

			.btn {
				margin-left: 20px;
				padding: 8px 19px;
			}
		}
	}

	.content-container {
		display: flex;
		flex-direction: row;
		gap: 30px;
		box-sizing: content-box;
		width: 100%;

		@include breakpoint(gtsm) {
			min-height: calc(100vh - 250px);
		}

		.left-sidebar {
			background: #ffffff;
			padding: 24px;
			width: 240px;
			min-width: 240px;
			box-sizing: content-box;

			.section-title {
				font-size: 18px;
				font-weight: 500;
				margin-bottom: 16px;
			}

			.progress-with-label {
				display: flex;
				flex-direction: column;

				.item {
					display: flex;
					flex-direction: row;

					.dot {
						background: #dedede;
						width: 16px;
						height: 16px;
					}

					.line {
						height: 16px;
						margin-left: 8px;
						margin-top: -5px;
						margin-bottom: 2px;
					}

					.label {
						font-size: 14px;
						font-weight: 300;
						color: #999999;
						margin-left: 10px;
					}

					&.active {
						.dot {
							background: #f3a533;
						}

						.label {
							color: #141414;
						}
					}
				}
			}
		}

		.right-sidebar {
			box-sizing: content-box;
			background: #ffffff;
			padding: 40px 32px 32px 24px;
			display: flex;
			flex-direction: column;
			flex-grow: 1;
			flex-basis: auto;
			position: relative;

			.row-with-single-col-with-half-size {
				.col {
					padding-right: 5px;
				}
			}

			.col {
				//min-width: 422px;
			}
		}
	}
}

.project-details-form {
	padding-bottom: 30px;
	height: 100%;

	.dropdown-with-tags {
		display: flex;
		flex-direction: column;
		max-width: 50%;

		.shared-selection {
			//max-width: 274px !important;
			//min-width: 274px !important;
			display: flex;
			flex-direction: column;
			width: 100%;
			min-width: 100%;

			.input-with-button {
				display: flex;
				flex-direction: row;
				gap: 11px;

				.custom-input {
					min-width: 313px;
					padding-bottom: 0;

					input {
						border: 1px solid $borderColorPattern3;
						border-radius: 0;
					}

					input::placeholder {
						/* Chrome, Firefox, Opera, Safari 10.1+ */
						color: #141414;
						opacity: 1; /* Firefox */
					}
				}

				.btn {
					font-size: 14px;
					line-height: 24px;
					color: #141414;
				}
			}
		}
	}

	.tags {
		padding-top: 16px;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		gap: 10px;

		.btn-tag {
			border-radius: 10px;
			padding: 4px 16px;
			line-height: 22px;
			color: #141414;
		}
	}

	.input-wrapper {
		display: flex;
		width: 50%;
		max-width: 50%;

		&.full {
			width: 100%;
			max-width: 100%;
		}

		.error-text {
			bottom: -20px;
		}
	}

	.select-assignment-item {
		display: flex;

		.label {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.internal-ref {
			margin-left: auto;
			color: #141414;
			opacity: 0.5;
			flex-shrink: 0;
		}
	}

	.select-assignment__option {
		&.select-assignment__option--is-selected {
			.select-assignment-item {
				.internal-ref {
					color: #fff;
				}
			}
		}
	}

	.select-assignment__control {
		.select-assignment-item {
			> .internal-ref {
				display: none;
			}
		}
	}
}

.project-settings-form {
	height: 100%;

	.checkbox-with-label {
		margin: 16px 0;

		label span {
			font-size: 16px;
			line-height: 26px;
			color: #141414;
			font-weight: 400;
		}
	}
}

.fit-to-brief-project-form {
	height: 100%;

	.col {
		margin: 16px 0;
	}

	label {
		font-size: 16px;
		line-height: 26px;
		color: #141414;
		font-weight: 400;
		margin-bottom: 10px;
	}

	.textarea-wrapper {
		display: flex;
		margin-top: 10px;
		position: relative;

		&.invalid-input {
			padding-bottom: 30px;
		}

		.error-text {
			bottom: 10px;
		}
	}

	.text-area-custom {
		font-weight: 300;
		color: #141414;
	}
}

.additional-criteria-form {
	height: 100%;

	.custom-input {
		padding-bottom: 21px;

		input {
			border: 1px solid #dedede;
			max-width: 352px;
			padding-bottom: 10px;
		}
	}

	.tags {
		display: flex;
		gap: 12px;
		flex-direction: row;

		.btn-tag {
			border-radius: 10px;
			line-height: 22px;
			color: #141414;
			padding: 4px 16px;
			display: flex;
			align-items: center;
			justify-content: center;

			svg {
				margin-left: 8px;
			}
		}
	}
}

.main-content-area {
	.footer {
		margin-top: 20px;
		display: flex;
		flex-direction: row;
		align-items: flex-end;
		justify-content: flex-end;

		.btn {
			margin-left: 20px;
			@include font14-light(400);
			line-height: 24px;
			padding: 7px 16px;

			svg {
				margin-right: 0;
				margin-left: 10px;
			}

			&.has-pre-icon {
				svg {
					margin-right: 10px;
					margin-left: 0;
				}
			}
		}
	}
}
