.summary-page {
	overflow: auto;

	.container {
		@include containerPadding();

		.content {
			display: flex;
			flex-direction: row;

			@include breakpoint(xs) {
				flex-direction: column-reverse;
				height: auto !important;
				margin-top: 20px;
			}
		}

		.left-column {
			padding: 20px 30px 20px 0;
			display: flex;
			flex-direction: column;
			width: 100%;

			@include breakpoint(xs) {
				width: 100%;
				padding: 12px 0 12px 0;
			}

			.row {
				width: 100%;
				display: flex;
				flex-direction: column;

				@include breakpoint(gtxs) {
					flex-direction: row;
					justify-content: space-between;
				}

				.section-title {
					@include section-title;

					svg {
						margin-left: 10px;

						&:hover {
							cursor: pointer;
						}
					}

					@include breakpoint(xs) {
						// margin-bottom: 20px;
						font-size: 16px;
					}
				}

				@include breakpoint(xs) {
					.pin-item {
						display: flex;
						width: 100%;

						.btn-red {
							width: 100%;
						}
					}
				}

				.add-update {
					display: flex;
					justify-content: center;
					align-items: center;
					gap: 10px;
					cursor: pointer;

					.label {
						font-weight: 400;
						font-size: 14px;
						line-height: 24px;
					}
				}
			}

			.client-summary-card-wrapper {
				border: 1px solid #cdcecf;
				max-height: 690px;
				overflow-y: auto;
				background: #ffffff;
				display: flex;
				flex-direction: column;
				margin-top: 21px;

				@include breakpoint(xs) {
					margin-top: 8px;
				}

				.summary-info {
					flex-grow: 1;
				}

				.unread {
					background: #faf7f1;

					.avatar,
					.summary-info {
						border-bottom: none;
					}
				}

				.read {
					.avatar,
					.summary-info {
						padding-bottom: 16px;
					}

					.short-desc {
						color: #141414;
					}

					&:last-child {
						.avatar,
						.summary-info {
							border-bottom: none;
						}
					}
				}
			}

			.candidate-summary-card {
				padding: 20px;
				display: flex;
				flex-direction: row;
				//margin-bottom: 25px;
				border-bottom: 1px solid $borderColorPattern3;

				@include breakpoint(xs) {
					padding: 16px;
				}

				.avatar {
					padding-right: 12px;

					img {
						height: 48px;
						border-radius: 50%;

						@include breakpoint(xs) {
							height: 32px;
						}
					}

					// border-bottom: 1px solid $borderColorPattern3;
				}

				.summary-info {
					display: flex;
					flex-direction: column;
					// border-bottom: 1px solid $borderColorPattern3;

					.meta-info {
						display: flex;
						flex-direction: row;
						justify-content: space-between;

						@include breakpoint(xs) {
							//flex-direction: column;
						}

						.intro {
							display: flex;
							flex-direction: column;

							.title {
								font-weight: 500;
								font-size: 16px;
								line-height: 26px;
								color: #141414;

								@include breakpoint(xs) {
									font-size: 12px;
									line-height: 22px;
								}
							}

							.designation {
								font-weight: 400;
								font-size: 12px;
								line-height: 18px;
								color: #999999;
							}
						}

						.extra-meta {
							display: flex;
							align-items: center;

							@include breakpoint(xs) {
								flex-direction: column-reverse;
								align-items: flex-end;
							}

							.date {
								margin-right: 10px;
								font-size: 12px;
								line-height: 26px;
								color: #141414;

								&.client-mode {
									padding-right: 48px;

									@include breakpoint(xs) {
										padding-right: 0;
									}
								}

								@include breakpoint(xs) {
									line-height: 22px;
									margin-right: 0;
								}
							}

							.is-read {
								min-width: 48px;
								color: #bd281e;
								font-weight: 500;
								font-size: 14px;
								line-height: 14px;

								@include breakpoint(xs) {
									// display: none;
								}
							}

							.edit-icon {
								margin-left: 8px;

								@include breakpoint(xs) {
									margin-left: 0;
								}
							}
						}
					}

					.short-desc {
						margin-top: 16px;
						font-weight: normal;
						font-size: 13px;
						line-height: 20px;
						color: $black;

						p {
							margin: 0 0 8px 0;

							&:last-child {
								margin-bottom: 0;
							}
						}
					}
				}
			}

			.market-insight-card-wrapper {
				border: 1px solid #cdcecf;
				background: #ffffff;
				margin-top: 20px;

				.section-title {
					padding: 20px 20px 0 20px;
					@include section-title-light;

					@include breakpoint(xs) {
						padding: 16px 16px 0 16px;
					}
				}

				.market-insight-card {
					padding: 20px 0;
					margin: 0 20px;
					display: flex;
					flex-direction: row;
					border-bottom: 1px solid $borderColorPattern2;

					@include font13-light(300);
					@include breakpoint(xs) {
						flex-direction: column;
						padding: 16px 0;
						margin: 0 16px;
					}

					.block-title {
						flex: 0 0 200px;
						font-weight: 400;
						padding-right: 22px;

						@include breakpoint(xs) {
							flex: 0 0 30px;
							border-bottom: none;
							padding-right: 0;
						}
					}

					.description {
						color: #141414;
						width: 100%;
					}
				}
			}

			.insight-report {
				border: 1px solid #cdcecf;
				margin-top: 20px;
				background: #ffffff;
				display: flex;
				flex-direction: column;
				padding: 20px;

				.section-title {
					@include section-title-light;
				}

				.description-area {
					display: flex;
					padding: 20px 0;
					align-items: stretch;

					.cover {
						height: 152px;
						padding-right: 20px;
					}

					@include breakpoint(xs) {
						flex-direction: column;

						.cover {
							display: flex;
							justify-content: center;
							margin-bottom: 20px;
							padding-right: 0;
							height: auto;

							img {
								width: 100%;
							}
						}
					}

					.description {
						.desc {
							@include font13-light(300);
							color: #141414;

							p {
								margin: 0 0 8px 0;

								&:last-child {
									margin-bottom: 0;
								}
							}
						}

						.btn {
							float: right;
							line-height: 26px;
							font-size: 14px;
							padding: 7px 32px;
							margin-top: 20px;
							font-weight: 500;
						}
					}
				}
			}
		}

		.right-column {
			width: 393px;
			padding: 12px 0;

			@include breakpoint(xs) {
				width: 100%;
			}

			.section-title {
				align-items: center;

				.total-counts {
					font-size: 14px;
					font-weight: 400;
					line-height: 22px;
					color: #141414;
				}
			}

			.card {
				border: 1px solid #cdcecf;
				background: #ffffff;
				padding: 22px 20px 20px 20px;
				margin-top: 16px;

				.section-title {
					@include section-title-light;
					margin-bottom: 18px;
				}

				.info-with-progress {
					border-bottom: 1px solid $borderColorPattern1;
				}

				.chart-row {
					display: flex;
					justify-content: space-between;
					padding: 21px 0;

					.section-title {
						svg {
							margin-left: 10px;

							&:hover {
								cursor: pointer;
							}
						}
					}

					.navigate-arrows {
						display: flex;
						justify-content: flex-end;

						.icon-right {
							margin-right: 27px;
						}
					}
				}
			}

			.document-download-section {
				margin-top: 20px;
				background: #ffffff;
				padding: 20px;
				display: flex;
				flex-direction: column;
				border: 1px solid #cdcecf;

				.section-title {
					@include section-title-light;
					display: flex;
					justify-content: space-between;

					svg {
						&:hover {
							cursor: pointer;
						}
					}
				}

				.document-item {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					padding-bottom: 16px;
					padding-top: 16px;
					border-bottom: 1px solid $borderColorPattern2;

					@include breakpoint(gtsm) {
						flex-direction: row;
						align-items: center;
					}

					&:last-child {
						border-bottom: none;
						padding-bottom: 0;
					}

					.label {
						@include font14-light(400);
						display: flex;
						align-items: center;
						//margin-bottom: 10px;

						@include breakpoint(gtsm) {
							margin-bottom: 0;
						}

						svg {
							height: 30px;
							margin-right: 5px;
							margin-left: -4px;

							@include breakpoint(gtmd) {
								margin-right: 10px;
							}

							@include breakpoint(gtlg) {
								margin-right: 16px;
							}
						}
					}
				}

				.download {
					display: flex;

					.btn {
						font-size: 12px;
						line-height: 22px;
						//font-weight: 300;

						&:first-child {
							margin-right: 10px;

							@include breakpoint(gtmd) {
								margin-right: 20px;
							}
						}

						svg {
							margin-right: 8px;
						}
					}
				}

				.edit-icon {
					display: flex;
					align-items: center;
					margin-left: 10px;
				}
			}
		}
	}
}

.left-column,
.right-column {
	.fit-to-brief {
		margin-top: 20px;
		background: #ffffff;
		border: 1px solid #cdcecf;
		padding: 20px;

		.section-title {
			@include section-title-light;
			margin-bottom: 16px;
		}

		.brief-card {
			display: flex;
			flex-direction: column;
			padding-bottom: 15px;

			&:last-child {
				padding-bottom: 0;
			}

			.title {
				@include font14-light(400);
				line-height: 24px;
			}

			.description {
				@include font13-light(300);
				color: #141414;
				margin-top: 8px;
			}
		}
	}
}

.main-content-area {
	.chart-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		align-content: center;
		padding: 0;

		.chart {
			width: 50%;
			height: auto;
			min-height: 100px;
			justify-content: flex-end;
		}

		.legend {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			width: 50%;
			height: 100%;
			//margin-left: 15px;
			font-size: 14px;
			line-height: 20px;
			font-weight: 300;
			color: #141414;

			.item {
				justify-content: flex-start;
				display: flex;
				font-weight: 400;
				font-size: 14px;
				line-height: 20px;
				min-width: 220px;

				@include breakpoint(xs) {
					min-width: auto;
				}

				@include breakpoint(xxs) {
					justify-content: space-around;
				}

				.label {
					width: 50%;
				}

				.info {
					font-size: 12px;
					line-height: 20px;
					font-weight: 400;
				}
			}
		}
	}
}

.edit-icon {
	margin-right: 10px;

	@include breakpoint(xs) {
		margin-right: 0;
	}
}

.highlighted-profiles-button {
	display: flex;
	align-items: center;
	justify-content: space-between;
	min-height: 40px;
	padding: 9px 16px;
	background: #fff;
	border: 1px solid rgba(112, 44, 46, 0.5);

	.label {
		font-weight: 400;
		font-size: 14px;
		line-height: 22px;
		display: flex;
		align-items: center;
		gap: 8px;
		margin-right: 2px;
	}

	&:hover {
		border: 1px solid #702c2e;
	}

	&.active {
		background: #efefef;
		border: 1px solid #702c2e;
	}
}
