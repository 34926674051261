.assignment-page {
	$headerRowGridHeight: 70px;
	$titleHeight: 30px;
	$titleMargin: 16px;
	$breakPoint: 550px;

	--pageTitleHeight: calc(#{$pageTitlePadding} * 2 + #{$inputHeight});

	@media (max-width: $breakPoint) {
		--pageTitleHeight: calc(
			#{$pageTitlePadding} * 2 + #{$inputHeight} + #{$titleHeight} + #{$titleMargin}
		);
	}

	width: 100%;

	.page-title {
		& > span {
			height: $titleHeight;
			margin-bottom: $titleMargin;
		}

		height: var(--pageTitleHeight);

		@media (max-width: $breakPoint) {
			flex-direction: column;
			align-items: flex-start !important;
		}
	}

	.report-flex {
		display: flex;

		> a {
			margin-left: 20px;
		}

		@media (max-width: $breakPoint) {
			width: 100%;
		}
	}

	.assignments {
		// @include breakpoint(gtsm) {
		// 	width: 100% !important;
		// }

		.card-wrapper {
			height: 100%;
			width: 100%;

			.content-block {
				$marginTop: 8px;
				overflow: auto;
				// KEEP -> Moved scroll from InfiniteScroll container to body:
				// height: calc(100% - #{$headerRowGridHeight});

				@include breakpoint(xxs) {
					margin-top: $marginTop;
					// KEEP -> Moved scroll from InfiniteScroll container to body:
					// height: calc(100% - #{$marginTop});
				}

				@include breakpoint(xs) {
					margin-top: $marginTop;
					// KEEP -> Moved scroll from InfiniteScroll container to body:
					// height: calc(100% - #{$marginTop});
				}

				@include breakpoint(sm) {
					margin-top: $marginTop;
					// KEEP -> Moved scroll from InfiniteScroll container to body:
					// height: calc(100% - #{$marginTop});
				}
			}

			.card {
				// display: flex;
				// flex-direction: row;
				// justify-content: flex-start;
				// align-items: center;
				// width: 100%;
				padding: 22px 20px;

				@include breakpoint(xxs) {
					padding: 16px;
				}

				@include breakpoint(xs) {
					padding: 16px;
				}

				&.header-row-grid {
					display: grid;
					height: $headerRowGridHeight;
					grid-template-columns: 20% 25% 21% 15% 11% 0%;

					@include breakpoint(xxs) {
						display: none;
					}

					@include breakpoint(xs) {
						display: none;
					}

					@include breakpoint(sm) {
						display: none;
					}
				}

				&.pinned {
					border: 1px solid #ddc89a !important;
				}

				&.inactive {
					opacity: 0.5;
				}

				&.header-row {
					.item {
						padding-left: 10px;
						height: 26px;
						text-overflow: ellipsis;
						white-space: nowrap;
						overflow: hidden;

						@include breakpoint(gtmd) {
							padding-left: 20px;
						}
					}
				}

				&.content-row {
					background: #ffffff;
					border: 1px solid #cdcecf;
					box-sizing: border-box;
					margin-bottom: 16px;

					.item {
						min-height: 48px;
						border-right: 1px solid $borderColorPattern2;
						padding: 0 10px;

						@include breakpoint(gtsm) {
							padding: 0 20px;
						}

						@include breakpoint(xxs) {
							border-right: none;

							&:nth-child(1) {
								grid-column: 1 / 3;
							}
						}

						@include breakpoint(xs) {
							border-right: none;

							&:nth-child(1) {
								grid-column: 1 / 3;
							}
						}

						@include breakpoint(sm) {
							border-right: none;

							&:nth-child(1) {
								grid-column: 1 / 3;
							}
						}

						&:last-child {
							border-right: none;
						}

						&.company-wrapper {
							.company {
								display: flex;
								align-items: center;
							}

							.clickable {
								&:hover {
									text-decoration: underline;
								}

								:hover {
									cursor: pointer;
								}
							}
						}
					}
				}

				&.content-row:last-child {
					margin-bottom: 0;
				}

				.item {
					min-width: 50px;

					&:first-child {
						padding-left: 0;
					}

					&:last-child {
						padding-right: 0;
					}

					span {
						svg {
							margin-left: 2px;

							@include breakpoint(gtmd) {
								margin-left: 9px;
							}
						}
					}

					&.company-wrapper,
					&.company {
						.name {
							padding-left: 12px;
							font-size: 14px;
							font-weight: 500;
							line-height: 26px;
							display: flex;
							flex-wrap: wrap;

							@include breakpoint(gtsm) {
								font-size: 15px;
							}

							@include breakpoint(gtmd) {
								font-size: 16px;
							}
						}
					}

					&.job-title {
						font-size: 14px;
						line-height: 22px;
						font-weight: 400;
						flex-wrap: wrap;
					}

					&.start-date {
						font-size: 14px;
						line-height: 26px;
						font-weight: 400;
					}

					&.status {
						font-size: 14px;
						line-height: 26px;
						font-weight: 400;
						width: calc(110px + 2 * 16px);

						svg {
							width: 100%;
							margin-bottom: 8px;

							@include breakpoint(xxs) {
								display: none;
							}

							@include breakpoint(xs) {
								display: none;
							}

							@include breakpoint(sm) {
								display: none;
							}
						}

						@include breakpoint(gtsm) {
							font-size: 14px;
						}
					}

					&.candidates,
					&.progress-status {
						font-size: 14px;
						line-height: 26px;
						font-weight: 400;

						.value {
							margin-top: 9px;
							font-size: 13px !important;
							line-height: 14px !important;

							@include breakpoint(gtsm) {
								font-size: 14px !important;
							}

							@include breakpoint(gtmd) {
								font-size: 16px !important;
							}
						}

						@include breakpoint(gtsm) {
							font-size: 14px;
						}
					}

					&.shared-with {
						width: 18%;

						@include breakpoint(gtmd) {
							width: 170px;
						}

						.persons-group {
							display: flex;
							flex-direction: row;

							.person {
								display: flex;
							}

							.count {
								color: #141414;
								font-size: 14px;
								line-height: 26px;
								font-weight: lighter;
							}
						}
					}
				}
			}

			.header-row {
				.item {
					font-size: 13px !important;
					font-weight: 500 !important;

					@include breakpoint(gtsm) {
						font-size: 14px !important;
					}

					@include breakpoint(gtmd) {
						font-size: 16px !important;
					}
				}
			}

			.content-row {
				.item {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					justify-content: flex-start;
					align-self: center;

					@include breakpoint(xxs) {
						justify-content: flex-start;
						align-self: flex-start;
					}

					@include breakpoint(xs) {
						justify-content: flex-start;
						align-self: flex-start;
					}

					@include breakpoint(sm) {
						justify-content: flex-start;
						align-self: flex-start;
					}

					&.item-actions {
						display: flex;
						flex-direction: row;
						align-items: center;
						justify-content: flex-start;

						.pin-btn {
							cursor: pointer;
							margin-left: 23px;
							flex-shrink: 0;
						}
					}

					& > .category {
						display: none;

						font-weight: 400;
						font-size: 14px;
						line-height: 22px;
						color: #999999;
						margin-bottom: 4px;

						@include breakpoint(xxs) {
							display: inline-block;
						}

						@include breakpoint(xs) {
							display: inline-block;
						}

						@include breakpoint(sm) {
							display: inline-block;
						}
					}

					& > .note {
						@include breakpoint(xxs) {
							display: none;
						}

						@include breakpoint(xs) {
							display: none;
						}

						@include breakpoint(sm) {
							display: none;
						}
					}
				}

				.start-date {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					justify-content: center;

					.note {
						margin-left: 0;
						font-size: 12px;
						line-height: 18px;
						color: #141414;
						margin-top: 3px;
					}
				}
			}
		}
	}

	.researcher {
		// KEEP -> Moved scroll from InfiniteScroll container to body:
		// height: calc(
		// 	100vh - #{$navbarHeight} - #{$containerPaddingTopBottom} * 2 - var(--pageTitleHeight)
		// );

		.progress-status {
			&.progress-block {
				margin-right: 0 !important;
			}
		}

		.shared-with {
			display: flex !important;
			align-items: center !important;
		}

		.content-row {
			.shared-with {
				@include breakpoint(gtxs) {
					align-items: flex-start !important;
				}
			}
		}
	}
}

.row-grid {
	display: grid !important;
	grid-template-columns: 20% 25% 21% 15% 11% 7%;

	&:first-child {
		display: flex;
	}

	@include breakpoint(sm) {
		grid-gap: 20px;
		grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
		align-items: center;
	}
	@include breakpoint(xs) {
		grid-gap: 20px;
		grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
		align-items: center;
	}
	@media (max-width: 605px) {
		grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
	}
	@media (max-width: 520px) {
		grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
	}
	@include breakpoint(xxs) {
		grid-gap: 20px;
		grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
		align-items: center;
	}
}
