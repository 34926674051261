@import "styles/variables.module.scss";
@import "styles/common.module.scss";

.navbar {
	background: #3e1f23;
	color: #fff;
}

.logo {
	height: 35px;
}

.container {
	composes: containerMain;
	align-items: center;
	padding: 8px 15px;
	// min-height: 120px;

	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: space-between;
}

.left {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: flex-start;
}

.updateInfo {
	font-size: 12px !important;
	padding-right: 10px !important;
	line-height: 24px;
	color: #cdcecf;
	display: inline-flex;

	svg {
		&:hover {
			cursor: pointer;
		}
	}

	.label {
		display: none;
	}

	@media #{$viewportMobileS} {
		display: none;
	}
	@media #{$viewportMobile} {
		display: none;
	}
}

.profileLink {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background: #fff;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	font-weight: 500;
	font-size: 14px;
	color: #000;
	text-transform: uppercase;
	margin-left: 13px;
}
