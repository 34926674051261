.progressInput {
	border: none;
	outline-style: none;
	width: 80%;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	min-height: 25px;
	resize: none;

	::placeholder {
		color: #999999;
	}
}

.progressInput:focus {
	outline: none;
}

.disabledWrapper {
	border-color: #dedede !important;
}

.disabledInput {
	cursor: pointer;
}

.default {
	cursor: default !important;
}

.progressWrapper {
	border-bottom: 1px solid #702c2e;
	width: 60%;
	padding-bottom: 6px;
	display: flex;
	justify-content: space-between;

	span {
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		color: #999999;
		cursor: pointer;
		margin-right: 6px;
	}

	span.save {
		color: #2ba44f !important;
	}
}

.label {
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	color: #999999;
}

.markdownContainer {
	width: 100%;
	font-size: 14px;
	min-height: 25px;

	&.researchMode {
		cursor: pointer;
	}

	p {
		margin: 0; // regular text is being rendered as p tag in ReactMarkdown
	}
}

.actionGroup {
	margin-top: auto;
}
