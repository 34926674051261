$break: 880px;
$secondBreak: 650px;

.user-management-page {
	.header-section {
		width: 100%;
		margin-top: 35px;
		margin-bottom: 40px;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		gap: 16px;

		@include breakpoint(gtsm) {
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
		}

		.page-title {
			padding: 0 !important;
		}

		.search-filter {
			width: 100%;

			@include breakpoint(gtsm) {
				width: auto;
			}

			input {
				width: 100%;
				@include breakpoint(gtsm) {
					width: 322px;
				}
			}
		}

		.add-new {
			width: 100%;

			@include breakpoint(gtsm) {
				width: auto;
			}

			.add-new-user-btn {
				width: 100%;

				@include breakpoint(gtsm) {
					padding: 8px 32px;
				}
			}
		}
	}

	.user-management-table {
		.card-wrapper {
			display: flex;
			flex-direction: column;
			width: 100%;
			color: #000000;

			.header-row {
				font-size: 16px;
				line-height: 26px;
				font-weight: 500;
				margin-bottom: 20px;

				.col {
					padding: 0 20px;

					&:hover {
						cursor: pointer;
					}
				}
			}

			.content-row {
				background: #ffffff;
				border: 1px solid #cdcecf;
				box-sizing: border-box;
				margin-bottom: 16px;

				.col {
					min-height: 56px;
					padding: 0 20px;
					display: flex;
					align-items: center;
					line-height: 24px;

					&.role {
						display: flex;
						justify-content: space-between;
					}
				}
			}

			.card {
				display: flex;
				flex-direction: row;

				.col {
					flex-grow: 1;

					&.first-name {
						width: 20%;
						max-width: 20%;

						@include breakpoint(gtmd) {
							width: 250px;
							max-width: 250px;
						}
					}

					&.last-name {
						width: 25%;
						max-width: 25%;

						@include breakpoint(gtmd) {
							width: 300px;
							max-width: 300px;
						}
					}

					&.company {
						width: 25%;
						max-width: 25%;

						@include breakpoint(gtmd) {
							width: 350px;
							max-width: 350px;
						}
					}

					&.role {
						.icon-group {
							display: flex;
							align-items: center;

							svg {
								margin-left: 20px;

								&:hover {
									cursor: pointer;
								}
							}
						}
					}
				}
			}
		}
	}
}

.user-form-page {
	.footer {
		margin-top: 24px;

		> button {
			min-width: 160px;
		}

		@media (max-width: $secondBreak) {
			flex-direction: column-reverse;

			button {
				width: 100%;

				&:not(:first-child) {
					margin-bottom: 24px;
				}
			}
		}
	}

	> .content-wrapper > .container > .grid {
		display: flex;
		align-items: flex-start;
		gap: 30px;

		@media (max-width: $break) {
			flex-direction: column;
			align-items: unset;
		}

		> .col {
			& > grid {
				@media (max-width: $break) {
					gap: 30px;
				}
			}
		}

		// Represents the parent of each input wrapper.
		// These code lines requered to overwrite the
		// global _plugings.scss ".grid.row".
		.grid.row {
			margin: 0 0 24px 0;

			&:last-child {
				margin-bottom: 0;
			}

			@media (max-width: $secondBreak) {
				flex-direction: column;

				> .input-wrapper {
					width: 100%;
				}
			}
		}
	}

	.avatar-container {
		background: #ffffff;
		border: 1px solid #cdcecf;
		box-sizing: border-box;
		padding: 24px;
		max-width: 287px;

		@media (max-width: $break) {
			max-width: 100%;
			padding: 16px;
		}

		.title {
			display: flex;
			justify-content: space-between;

			svg {
				cursor: pointer;
			}

			@media (max-width: $break) {
				font-size: 16px;
			}
		}

		.btn-white:disabled {
			.file-input {
				display: none;
			}
		}

		.avatar-box {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			margin-top: 24px;

			@media (max-width: $break) {
				margin-top: 16px;
			}

			.box {
				width: 100%;
				aspect-ratio: 1/1;
				border: 1px solid #cdcecf;
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 24px;

				img {
					width: 100%;
					border-radius: 50%;
					aspect-ratio: 1/1;
				}

				@media (max-width: $break) {
					border: none;
					// margin: 0;
					padding: 0;
					aspect-ratio: unset;
					width: 160px;
					height: 160px;

					img {
						height: 160px;
					}
				}
			}

			.file-input {
				position: absolute;
				top: 0;
				right: 0;
				margin: 0;
				padding: 0;
				font-size: 20px;
				cursor: pointer;
				opacity: 0;
				filter: alpha(opacity=0);
				height: 100%;
			}

			.btn {
				position: relative;
				overflow: hidden;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				width: 100%;
				font-size: 14px;
				line-height: 22px;
				margin-top: 16px;

				svg {
					margin-right: 8px;
					margin-left: 0;
				}

				@media (max-width: $break) {
					width: 100%;
				}
			}
		}
	}

	.user-form {
		background: #ffffff;
		border: 1px solid #cdcecf;
		box-sizing: border-box;
		padding: 24px;
		display: flex;
		flex-direction: column;

		@media (max-width: $break) {
			padding: 16px;
		}

		.title {
			line-height: 26px;
			margin-bottom: 24px;

			@media (max-width: $break) {
				margin-bottom: 16px;
				font-size: 16px;
			}
		}

		.input-wrapper {
			input,
			.custom-select select {
				border: 1px solid $borderColorPattern3;
				border-radius: 0;
				padding: 8px 16px;
			}
		}

		.row {
			width: 100%;
			display: flex;
			flex-direction: row;

			.col {
				display: flex;
				flex-grow: 1;
				width: 49%;

				&.input-wrapper {
					.custom-input {
						width: 100%;
						padding-bottom: 0;
						margin-bottom: 0;
					}
				}
			}
		}
	}
}
