.modal {
	padding: 24px;
	user-select: none;
}

.modalTitle {
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-bottom: 1px solid #f2f2f2;
}

.preview {
	text-align: center;
	margin-bottom: 24px;

	> img {
		border-radius: 50%;
		cursor: pointer;
		width: 198px;
		height: 198px;
		object-fit: contain;
		border-radius: 50%;
	}
}

.title {
	margin-bottom: 8px;
	line-height: 26px;
	display: flex;

	> span {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 220px;
		display: inline-block;
		margin: 0 4px;
	}
}

.content {
	margin-top: 16px;
}

.input {
	width: 100%;
	margin-right: 8px;

	> label {
		color: #141414;
		font-size: 14px;
		line-height: 24px;
		margin-bottom: 6px;
		font-weight: 600;
	}

	input {
		border: 1px solid #dedede;
		border-radius: 0;
	}
}

.row {
	display: flex;
	align-items: flex-end;
}

.uploadButton {
	position: relative;
	margin-bottom: auto;
	margin-top: 30px;

	// &.validation {
	// 	margin-bottom: 21px;
	// }
}

.submit {
	width: 100%;
	font-size: 14px;
	margin-top: 24px;
}

.clipboard {
	color: #141414;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
	display: inline-flex;
	align-items: center;
	cursor: pointer;
	margin: 6px 0 24px 0;

	&:hover {
		color: #702c2e;

		g {
			fill: #702c2e;
		}
	}
}

.hide {
	visibility: hidden;
}

.absolute {
	position: absolute;
}

.restoreContainer {
	width: 100%;
	text-align: center;
}

.restore {
	color: #999999;
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	margin-top: 16px;

	&:hover {
		color: #702c2e;

		svg g > path {
			fill: #702c2e;
		}
	}
}

.noIconMargin svg {
	margin-right: 0;
}
