@import "./themes.module.scss";

//font color

$white: #fff;
$green: #39a139;
$lightGray: #666;
$gray: #333;
$darkBlue: #171f46;
$lightBlue: rgba(215, 223, 233, 0.24);
$red: #e2231a;
$lightRed: rgba(255, 0, 0, 0.12);

// mapx variable
$themeColor: rgba(49, 179, 172, 0.85);
$blackOpacity: rgba(0, 0, 0, 0.5);
$inputBorder: rgba(42, 35, 100, 0.5);
$inputColor: #697d95;
$errorColor: #e84e0f;
//main

$containerWidth: 1270px;

//media variables
$viewportMobileS: "(max-width: 374px)";
$viewportMobileX: "(min-width: 375px)";
$viewportMobile: "(max-width: 767px)";
$viewportSmall: "(min-width: 550px)";
$viewportMedium: "(min-width: 768px)";
$viewportTablet: "(min-width: 768px) and (max-width: 1024px)";
$viewportLarge: "(min-width: 1024px)";
$viewportMinLarge: "(min-width: 1025px)";
$viewportLargeWithPaddings: "(min-width: 1128px)";
$viewportLargeS: "(min-width: 1200px)";
$viewportLargeSM: "(min-width: 1300px)";
$viewportLargeX: "(min-width: 1400px)";
$viewportXLarge: "(min-width: 1921px)";

//
@mixin placeholder {
	&::-webkit-input-placeholder {
		@content;
	}
	&:-moz-placeholder {
		@content;
	}
	&::-moz-placeholder {
		@content;
	}
	&:-ms-input-placeholder {
		@content;
	}
}
