@import "styles/variables.module.scss";

.switch {
	position: relative;
	display: inline-flex;
	width: 40px;
	height: 24px;
	margin: 0;
}

.input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 100%;
	width: 100%;
	border: 0;
	z-index: 1;

	&:checked {
		& ~ .switchBox {
			background-color: $red;

			&:after {
				right: 2px;
			}
		}
	}
}

.switchBox {
	background-color: #ccc;
	padding: 0;
	border-radius: 20px;
	cursor: pointer;
	display: inline-block;
	height: 100%;
	overflow: hidden;
	position: relative;
	width: 100%;
	box-sizing: border-box;

	&:after {
		background-color: #fff;
		border-radius: 20px;
		content: " ";
		display: block;
		height: 20px;
		position: absolute;
		right: calc(50% - 2px);
		transition: all 0.1s linear;
		width: 20px;
		top: 2px;
	}
}
