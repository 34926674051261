@import "variables";
@import "mixins";
@import "general";
@import "radio";
@import "plugins";
@import "modal";
@import "checkbox";
@import "breadcrumb";
@import "assignments";
@import "summary";
@import "assessment";
@import "profile";
@import "project";
@import "user";

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: "Calibri", sans-serif;
}

textarea,
input,
button,
select {
	font-family: inherit;
	font-size: inherit;
}
