@import "styles/variables.module.scss";
@import "styles/Report/_mixins.scss";
@import "styles/form.module.scss";

.container {
	display: flex;
	flex-direction: column;
	flex: 1;
	background: url("../../assets/images/auth-bg.jpg") no-repeat center center;
	background-size: cover;
	justify-content: center;
	align-items: center;
	min-height: 100vh;
}

.title {
	font-size: 18px;
	line-height: 40px;
	font-weight: 400;
	text-align: center;
	margin: 0;
}

.subTitle {
	font-size: 14px;
	line-height: 24px;
	color: $lightGray;
	text-align: center;
	margin-top: 24px;
}

.content {
	padding: 20px;
	//margin: 106px auto 68px auto;
	background: rgba(255, 255, 255, 1);
	width: 300px;

	@media #{$viewportMedium} {
		width: 552px;
		padding: 50px 96px 30px 96px;
	}
}

.logoContainer {
	width: 100%;
	margin-bottom: 21px;
	text-align: center;
}

// .logo {
// 	width: 250px;
// 	height: 192px;
// 	background: url("../../assets/images/Savannah_logo.png") no-repeat center center;
// 	background-size: cover;
// 	margin: 0 auto;
// }

.submitButton {
	height: 40px;
	width: 100%;
	border: none;
	background: $red;
	font-weight: 500;
	font-size: 12px;
	line-height: 24px;
	text-transform: uppercase;
	letter-spacing: 0.05em;
	color: #fff;
	cursor: pointer;

	&:disabled {
		background: #f2f2f2;
		color: #999;
	}
}

.loginInput {
	margin: 0 0 21px 0 !important;

	label {
		font-size: 14px;
	}
}

.form {
	margin-top: 24px;
}

.title {
	font-weight: 400;
	font-size: 32px;
}

.links {
	margin-top: 32px;
	display: flex;
	justify-content: center;
	align-items: center;

	.linkForgot {
		margin-left: 8px;
	}
}

.linkForgot {
	font-size: 14px;
	line-height: 24px;
	color: $lightGray;
	padding: 10px;

	&:nth-of-type(even) {
		margin-left: auto;
	}
}

.error {
	color: #f00;
	margin: 0 0 20px 0;
}

.success {
	color: $green;
	margin-top: 0;
}

.linkContainer {
	text-align: center;
	padding: 15px 0;
}

.inputContainer {
	margin: 5px 0 16px 0;
}
