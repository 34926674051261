$base: 34px;

.breadcrumb-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-top: 8px;

	.breadcrumb {
		display: flex;
		overflow: hidden;
		margin: auto;
		text-align: center;
		top: 50%;
		width: 100%;
		height: $base * 1.5;
		z-index: 1;
		background-color: #fff;
		font-size: 16px;
		box-shadow: 1px -1px #dedede, -1px 1px #dedede;

		@include breakpoint(xs) {
			font-size: 13px;
		}
	}

	.breadcrumb a {
		position: relative;
		display: flex;
		flex-grow: 1;
		text-decoration: none;
		margin: auto;
		height: 100%;
		padding-left: $base;
		padding-right: 0;
		color: #666;
		cursor: pointer;
		user-select: none;
	}

	.breadcrumb a:first-child {
		padding-left: $base / 2.5;
		white-space: nowrap;
	}

	.breadcrumb a:last-child {
		padding-right: $base / 2.5;
		white-space: nowrap;
	}

	.breadcrumb a:after {
		content: "";
		position: absolute;
		display: inline-block;
		width: $base * 1.5;
		height: $base * 1.5;
		top: 0;
		right: $base / 1.35 * -1;
		background-color: #fff;
		border-top-right-radius: 5px;
		transform: scale(0.707) rotate(45deg);
		box-shadow: 1px -1px #dedede;
		z-index: 1;
	}

	.breadcrumb a:last-child:after {
		content: none;
	}

	.breadcrumb__inner {
		display: flex;
		flex-direction: column;
		margin: auto;
		z-index: 2;
	}

	.breadcrumb a:hover {
		background: #f0d3d0;
		color: #702c2e;
	}

	.breadcrumb a.active {
		background: #702c2e;
		color: #fff;
		font-weight: 500;
	}

	.breadcrumb a:hover:after {
		background: #f0d3d0;
		color: #702c2e;
	}

	.breadcrumb a.active:after {
		background: #702c2e;
		color: #fff;
	}
}

.simple-breadcrumb {
	list-style: none;
	padding: 0;
	display: flex;
	width: 100%;
	height: 40px;

	span {
		display: flex;
		justify-items: center;
		align-items: center;
		font-size: 16px;
		line-height: 30px;
		font-weight: 500;
		color: #373937;

		.link {
			margin-right: 11px;
		}

		svg {
			padding-top: 3px;
			margin-right: 11px;
			margin-left: 11px;
		}
	}

	span:first-child:before {
		content: normal;
	}

	span:last-child {
		color: #676767;
		font-weight: 400;
	}

	@media (max-width: 767px) {
		// flex-direction: column;

		span {
			font-size: 13px;
			line-height: 24px;
		}

		span.link {
			margin-right: 5px;
		}
	}
}
