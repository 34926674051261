@import "styles/variables.module.scss";
@import "styles/Report/_mixins.scss";

.inputContainer {
	display: flex;
	flex-direction: column;
	position: relative;
}

.errorText {
	top: 26px;
	width: 100%;
	height: 28px;
	background: $errorColor;
	color: #ffffff;
	border-radius: 3px;
	padding: 4px 8px;
	font-weight: 400;
	line-height: 20px;
	font-size: 13px;
	position: absolute;
	bottom: 10px;

	&:before {
		content: "";
		display: block;
		width: 0;
		height: 0;
		border-left: 8px solid transparent;
		border-right: 8px solid transparent;
		border-top: 8px solid $errorColor;
		position: absolute;
		bottom: -5px;
		left: 2px;
	}
}

.inputWrapper {
	display: flex;
	align-items: center;
	position: relative !important;
	width: 100%;
}

.invalid {
	& .iconError {
		display: block !important;
		color: #ff0b37 !important;
	}

	& .input {
		background: rgba(255, 11, 55, 0.05) !important;
		border: 1px solid #ff0b37 !important;
	}
}

.icon {
	position: absolute;
	right: 10px;
	line-height: 0.8;
	cursor: pointer;
}

.iconError {
	display: none !important;
	position: absolute;
	right: 10px;
	line-height: 0.8;
}

.input {
	@include input-design;
	border: 1px solid $inputBorder;
	border-radius: 3px;
	padding-right: 35px;

	@include placeholder {
		color: #697d95;
	}
}

.label {
	margin-bottom: 6px;
	color: $blackOpacity;
	text-transform: capitalize;
	font-size: 10px;
	line-height: 24px;
}

.invalid {
	position: relative;
	input {
		border: 1px solid $errorColor !important;
		background: #ffffff !important;
	}

	.error-icon {
		display: none !important;
	}
}

.invalidInput {
	border: 1px solid #ff0b37;
}

.errorTextSpan {
	font-size: 12px;
	line-height: 16px;
	color: #ff0b37;
	display: block;
	margin-top: 5px;
}
