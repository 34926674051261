.candidate-card-wrapper {
	display: flex;
	align-items: center;
	flex-direction: column;
}

.print-candidate-card {
	display: flex;
	flex-direction: row;
	height: auto;
	align-items: flex-start;
	background: #ffffff;

	&:last-child {
		border-bottom: none;
	}

	.avatar {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		position: relative;
		margin-top: 12px;
		margin-bottom: 10px;

		&.is-highlighted {
			.avatar-details {
				margin-top: 30px;
			}
		}

		.discussion {
			background: rgba(112, 44, 46, 0.09);
			text-align: center;
			position: absolute;
			top: 0;
			width: 100%;
			color: #bd281e !important;
			font-size: 13px;
			line-height: 16px;
			font-weight: 400;
			min-height: 24px;
			padding-top: 4px;
		}

		img {
			height: 56px;
			border-radius: 50%;
		}
	}

	.info {
		margin: 6px 8px;

		.label {
			font-style: normal;
			font-weight: 400;
			font-size: 13px;
			line-height: 24px;
			color: #999999;
		}

		.value {
			font-style: normal;
			font-weight: 400;
			font-size: 13px;
			line-height: 20px;
			display: flex;
			align-items: center;
			color: #141414;
		}
	}
}
