@import "styles/variables.module.scss";
@import "styles/Report/_mixins.scss";
@import "styles/form.module.scss";

.container {
	background: url("../../assets/images/auth-bg.png") no-repeat center center;
	background-size: cover;
	display: flex;
	flex-direction: column;
	flex: 1;
	justify-content: center;
	align-items: center;
	overflow-y: scroll;
	min-height: 100vh;
}

.title {
	font-weight: bold;
	font-size: 18px;
	line-height: 20px;
	display: flex;
	margin-bottom: 25px;
}

.subTitle {
	font-size: 14px;
	line-height: 24px;
	color: $lightGray;
	text-align: center;
	margin-top: 24px;
}

.content {
	width: 100%;
	position: relative;

	@include breakpoint(gtxs) {
		width: 600px;
	}
}

.logoContainer {
	position: absolute;
	top: -258px;
	width: 100%;

	.logo {
		width: 323px;
		height: 258px;
		background: url("../../assets/images/Savannah-mapX-logo_1500px.png") no-repeat
			center center;
		background-size: cover;
		margin: 0 auto;
	}
}

.actions {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.submitButton {
	border: none;
	border-radius: 3px;
	text-align: center;
	font-size: 14px;
	padding: 10px;
	background: #bd281e;
	color: #fff;
	cursor: pointer;
	display: flex;
	height: 40px;
	align-items: center;
	justify-content: center;

	&:disabled {
		background: #f2f2f2;
		color: #999;
	}
}

.submitButton:disabled {
	cursor: default;
}

.submitButton.loading {
	padding: 0;
}

.loginInput {
	margin: 0;
}

.loginButton {
	background-color: #31b3acd9;
	padding: 8px 12px 8px 12px;
	color: #fff;
	width: 116px;
}

.resetButton {
	margin-top: 21px;
	padding: 15px 30px;
	color: #fff !important;
	&:disabled {
		background: #dedede !important;
	}
}

.loginForm {
	margin: 25px !important;
	background-color: rgba(255, 255, 255, 0.8) !important;
}

.formInput {
	input {
		background-color: #fff !important;
	}
}

.form {
	background: rgba(255, 255, 255, 0.8);
	outline: 1px solid rgba(163, 156, 154, 0.5);
	outline-offset: -1px;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: center;

	@include breakpoint(gtxs) {
		width: 600px;
		padding: 26px 24px 24px 26px;
	}

	> * {
		width: 100%;
	}

	h1 {
		font-weight: 400;
		margin-bottom: 0;
	}

	> h1,
	p,
	button {
		text-align: center;
	}
}

.links {
	margin-top: 32px;
	display: flex;
	justify-content: flex-end;
}

.linkForgot {
	font-size: 14px;
	line-height: 24px;
	color: #999999;
	margin-left: 12px;
	position: relative;
	top: 1px;

	&:nth-of-type(even) {
		margin-left: auto;
	}
}

.error {
	color: #f00;
}

.success {
	color: $green;
	margin-top: 0;
}

.linkContainer {
	text-align: center;
	padding: 15px 0;
}

.inputContainer {
	margin: 5px 0 16px 0;
}

.forgotPass {
	margin-top: 20px !important;
}

.resetForm {
	padding: 46px 90px !important;
}
