@import "../global.module";

.tabs-wrapper {
	display: flex;
	width: 100%;

	@include breakpoint(xs) {
		justify-content: center;
		border-bottom: 1px solid $borderColorPattern2;
	}

	.tab {
		color: $gray;
		padding-bottom: 16px;
		font-size: 16px;
		line-height: 30px;
		font-weight: 400;
		margin-right: 41px;

		&:first-child {
			@include breakpoint(xs) {
				margin-right: 30px;
			}
			@include breakpoint(xxs) {
				font-size: 14px;
				margin-right: 12px;
			}
		}

		@include breakpoint(xs) {
			font-size: 14px;
			line-height: 22px;
			margin-right: 0;
		}

		&.active {
			color: $red;
			border-bottom: 3px solid $red;
		}

		&:hover {
			cursor: pointer;
		}
	}
}

.dropdown-block {
	display: flex;
	align-items: center;
	width: 288px;
	margin-right: 70px;

	.dropdown-label {
		padding-right: 12px;
		font-size: 14px;
		line-height: 24px;
		min-width: 45px;

		@include breakpoint(gtmd) {
			padding-right: 15px;
		}
	}

	.dropdown {
		width: 200px;
		font-size: 14px;

		@include breakpoint(gtmd) {
			width: 223px;
		}
	}
}

.btn {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 40px;
	padding: 0 19px;
	border-radius: 0;
	cursor: pointer;
	white-space: nowrap;
	text-align: center;
	background: #c3c3c3;
	color: #333333;
	font-size: 16px;
	line-height: 22px;
	font-weight: 400;

	&.btn-red {
		background: $darkRed;
		border: 1px solid $darkRed;
		color: #ffffff;
	}

	&.discussion-btn {
		font-weight: normal;

		&.btn-white {
			border: 1px solid $darkRed;
			cursor: pointer;

			&:disabled {
				cursor: not-allowed;
			}

			.pin-icon {
				svg path {
					fill: $darkRed !important;
				}
			}
		}

		&.active {
			font-weight: 500;
		}

		&:hover {
			//background: $lightRed !important;
			//border: 1px solid $lightRed !important;
			color: #ffffff !important;
			font-weight: 500;

			.pin-icon {
				svg path {
					fill: #ffffff !important;
				}
			}
		}
	}

	&.btn-transparent {
		background: transparent;
		border: 1px solid #141414;
		color: #141414;
		padding: 5px 8px;
	}

	&.btn-white {
		padding: 8px 16px;
		border: 1px solid #dedede;
		background: #ffffff;
		display: flex;
		cursor: pointer;
	}

	&.btn-white:disabled {
		background-color: rgb(220, 220, 220);
		opacity: 0.7;
		cursor: not-allowed;
	}

	&.btn-disabled {
		padding: 8px 16px;
		border: 1px solid #e3e3e3;
		background: #e3e3e3;
		display: flex;

		&.has-pre-icon {
			svg {
				margin-right: 10px;
			}
		}
	}

	&.btn-tag {
		font-size: 12px;
		line-height: 16px;
		color: #484848;
		background: #f7f7f7;
		border-radius: 2px;
		padding: 4px 10px;
		border: none;
	}

	&.close-button {
		padding: 9px !important;

		svg {
			margin: 0 !important;
		}
	}

	&.btn-disabled {
		opacity: 0.7;
	}

	svg {
		margin-right: 10px;
	}
}

.pin-item {
	.btn {
		font-size: 14px;
		line-height: 24px;
		height: 40px;
		min-width: 171px;
		display: flex;
		align-items: center;
		//padding: 7px 37px 7px 18px;

		.pin-icon {
			height: 22px;
			display: flex;
			align-items: center;
		}
	}
}

.dot {
	height: 10px;
	width: 10px;
	background-color: #bbb;
	border-radius: 50%;
	display: inline-flex;
	margin-right: 4px;
}

.error-text {
	font-size: 12px !important;
	line-height: 16px !important;
	color: #fff !important;
	position: absolute !important;
	bottom: 10px !important;
}

.search-filter {
	display: flex;
	align-items: center;
	width: 100%;

	& > svg {
		flex-shrink: 0;
	}

	input {
		width: 201px;
		height: 40px;
		background: #ffffff;
		border: 1px solid #dedede;
		padding: 8px 16px;
		@include font14-bold();

		&:focus {
			outline: none;
		}

		&:focus-within {
			//border: 2px solid #DEDEDE;
		}

		@media (max-width: 550px) {
			width: 100%;
		}
	}
}

.filters-details-wrapper {
	background: #ffffff;
	display: flex;
	flex-flow: wrap;
	flex-direction: row;
	padding: 30px 40px;
	margin: 20px 0;
	border: 1px solid #cdcecf;

	&.show {
		display: flex;
		gap: 80px;

		@include breakpoint(xs) {
			gap: 20px;
			padding: 15px;
		}
	}

	&.hide {
		display: none;
	}

	.column {
		display: flex;
		flex-direction: column;

		@include breakpoint(xs) {
			flex: 1 1 46%;
		}

		.header-label {
			font-size: 16px;
			font-weight: 500;
			line-height: 26px;
			margin-bottom: 18px;
		}
	}
}

.checkbox-wrapper {
	display: flex;
	flex-direction: column;
	margin-bottom: 10px;
}

.interview-schedule-wrapper {
	display: flex;
	flex-direction: row;
	background: #ffffff;
	//padding: 43px 32px;
	margin-top: 9px;
	margin-bottom: 19px;
	max-height: 450px;
	overflow: hidden;
	border: 1px solid #cdcecf;

	@include breakpoint(xs) {
		flex-direction: column;
		max-height: none;
	}

	&.show {
		display: flex;
	}

	&.hide {
		display: none;
	}

	.calendar {
		padding: 43px 0 30px 30px;
		min-width: 340px;

		@include breakpoint(xs) {
			padding: 0 15px;

			.section-title {
				padding-top: 15px;
			}
		}
	}

	.section-title {
		@include section-title();
		font-size: 16px;
		line-height: 26px;
		margin-bottom: 27px;
	}

	.timeline {
		flex-grow: 4;
		padding: 43px 30px 10px 10px;
		display: flex;
		flex-direction: column;

		@include breakpoint(xs) {
			padding-top: 0;
			padding-left: 0;
			padding-right: 0;
		}

		.filters {
			display: flex;
			flex-direction: row;
			//align-items: flex-end;
			justify-content: flex-end;
			margin-bottom: 20px;

			@include breakpoint(xs) {
				flex-direction: column;
				gap: 20px;
				//padding-top: 0;
			}

			.dropdown {
				padding-left: 20px;
				font-size: 14px;
				font-weight: 500;

				&:last-child {
					margin-right: 10px;

					@include breakpoint(xs) {
						margin-right: 0;
					}
				}

				@include breakpoint(xs) {
					width: 100% !important;
					padding: 0 15px;
				}
			}

			.interviewers {
				width: 166px;
			}

			.candidates {
				width: 162px;
			}

			.month {
				width: 140px;
			}

			.year {
				width: 102px;
			}
		}

		.timeline-details {
			@include breakpoint(gtxs) {
				overflow: auto;
			}
		}

		.row {
			display: flex;
			flex-direction: row;
			height: 107px;
			border: 1px solid $borderColorPattern2;
			border-bottom: none;

			&:last-child {
				border: 1px solid $borderColorPattern2;
			}

			@include breakpoint(xs) {
				height: auto;
			}

			.date-column {
				width: 52px;
				min-width: 52px;
				height: 100%;
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				color: #ffffff;

				@include breakpoint(xs) {
					min-width: 40px;
					width: 40px;
					//height: 140px !important;
				}

				.date {
					font-size: 24px;
					line-height: 30px;
					font-weight: 500;

					@include breakpoint(xs) {
						padding-top: 19px;
					}
				}

				.day {
					font-size: 12px;
					line-height: 18px;

					@include breakpoint(xs) {
						padding-bottom: 18px;
					}
				}

				.avatar {
					display: none;

					@include breakpoint(xs) {
						display: flex;
						align-items: center;
						justify-content: center;
						background: #ffffff !important;
						height: 85px;
						min-width: 40px;
						width: 40px;

						img {
							height: auto;
							width: 32px;
							border-radius: 30px;
						}
					}
				}
			}

			.info-columns {
				display: flex;
				flex-direction: row;

				@include breakpoint(xs) {
					flex-direction: column;
				}
			}

			.interviewer-column {
				width: 360px;
				min-width: 360px;
				display: flex;
				flex-direction: column;
				justify-content: center;
				//align-items: center;
				padding: 0 10px;
				border-right: 1px solid $borderColorPattern2;

				@include breakpoint(xs) {
					justify-content: flex-start;
					width: 100%;
					min-width: 100%;
					border-bottom: 1px solid $borderColorPattern2;
					min-height: 85px;
				}

				.section-title {
					@include section-title;
					font-size: 14px;
					line-height: 22px;
					margin-bottom: 8px;

					@include breakpoint(xs) {
						margin-bottom: 0;
						padding: 3px 0;
						display: flex;
					}
				}

				.meta-info {
					display: flex;
					flex-direction: row;

					.info {
						min-width: 100px;
						padding-right: 25px;
						//margin-right: 25px;

						&:last-child {
							padding-right: 15px;
						}
					}
				}
			}

			.candidate-column {
				display: flex;
				flex-direction: row;
				flex-grow: 2;
				padding: 30px 10px 10px;
				//align-items: center;

				@include breakpoint(xs) {
					padding-top: 10px;
					min-height: 85px;
				}

				.avatar {
					padding-left: 15px;
					padding-right: 15px;

					@include breakpoint(xs) {
						padding-left: 0;
						display: none;
					}

					img {
						height: 40px;
						border-radius: 50px;
					}

					.name-as-avatar {
						height: 40px;
						width: 40px;
						font-size: 16px;
						font-weight: 500;
					}
				}

				.info {
					min-width: 100px;
					padding-right: 20px;

					&:last-child {
						padding-right: 5px;
					}
				}
			}

			.info {
				&.interviewer,
				&.role {
					min-width: 150px !important;
				}

				.label {
					color: $gray;
					font-size: 12px;
					line-height: 18px;
				}

				.value {
					color: #141414;
					font-size: 12px;
					line-height: 18px;
					font-weight: 500;
				}
			}

			@include breakpoint(xs) {
				.info {
					padding-right: 10px !important;

					&.time,
					&.candidate {
						width: 80px !important;
						min-width: 80px !important;
						flex-flow: wrap;
					}

					&.interviewer,
					&.company {
						width: 120px !important;
						min-width: 120px !important;
						flex-flow: wrap;
					}

					&.location,
					&.role {
						width: 100px !important;
						min-width: 100px !important;
						flex-flow: wrap;
					}
				}
			}

			.bg-yellow-orange {
				@include breakpoint(xs) {
					&.xs-only {
						background: $yellowOrange;
						color: #ffffff !important;
						margin-left: -10px;
						margin-right: -10px;
						padding-left: 10px !important;
					}
				}
			}

			.bg-orange {
				@include breakpoint(xs) {
					&.xs-only {
						background: $orange;
						color: #ffffff !important;
						margin-left: -10px;
						margin-right: -10px;
						padding-left: 10px !important;
					}
				}
			}
		}
	}
}

.interview-schedule-wrapper-mobile {
	> .row {
		position: relative;

		> .fade {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			z-index: 1;
			background: rgba(255, 255, 255, 0.5);
		}

		> .date-column {
			background-color: $blackYellow;
			color: #fff;
			padding: 8px 16px;

			.date {
				font-size: 20px;
			}
			.interview-title {
				font-size: 14px;
			}
		}
		> .info-columns {
			padding: 16px;
			box-sizing: border-box;
			border-right: 1px solid #f2f2f2;
			border-left: 1px solid #f2f2f2;

			> .candidate-column {
				margin-bottom: 16px;

				> .candidate-avatar {
					display: flex;
					align-items: center;
					background: transparent;
					font-size: 18px;

					> .candidate-name {
						margin-left: 16px;
					}
				}
			}

			> .interviewer-column {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				grid-template-rows: repeat(2, 1fr);
				gap: 16px;

				> .info {
					> .label {
						color: #999999;
						font-size: 14px;
						margin-bottom: 4px;
					}
				}
			}
		}
	}

	&.previous {
		> .row {
			> .date-column {
				background-color: #999999;
			}
		}
	}
}

.DayPicker {
	font-family: "Inter", sans-serif;

	&:focus,
	.DayPicker-wrapper:focus {
		outline: none;
	}

	.DayPicker-Month {
		margin: 0;
	}

	//.DayPicker-NavBar {
	//  display: none;
	//}

	.DayPicker-Caption {
		text-align: center;
		padding: 0 0 16px 0;
		margin: 0;

		div {
			font-size: 16px;
			line-height: 26px;
			font-weight: 500;
		}
	}

	.DayPicker-Weekday {
		font-size: 13px;
		line-height: 18px;
		color: #141414;
		//width: 46px;
		padding: 5px;

		abbr {
			text-transform: uppercase;
		}
	}

	.DayPicker-Day {
		font-size: 16px;
		line-height: 24px;
		font-weight: 400;
		color: #676767;
		letter-spacing: 0.38px;
		height: 46px;
		width: 46px;
		padding: 0;

		&:focus {
			outline: none;
		}

		&.DayPicker-Day--selected {
			border-radius: 30px;
			color: #ffffff;

			&.DayPicker-Day--clientInterviews {
				background-color: $lightOrange;
			}

			&.DayPicker-Day--savannahInterviews {
				background-color: $orange;
			}

			&.DayPicker-Day--outside {
				background: transparent;
			}
		}
	}
}

.custom-tooltip {
	margin-top: 0 !important;
	max-width: 170px;
	display: flex;
	justify-items: flex-start;
	align-items: center;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.01);
	background: #ffffff !important;
	font-family: "Inter", sans-serif !important;
	font-size: 12px;
	line-height: 16px;
	font-weight: 400;
	color: $gray !important;
	border: none !important;
	padding: 8px !important;
	border-radius: 1px !important;

	&.show {
		opacity: 1 !important;
	}
}

.cursor {
	&:hover {
		cursor: pointer;
	}

	* {
		&:hover {
			cursor: pointer;
		}
	}
}

.close-icon-on-hover {
	svg {
		display: none;
	}

	&:hover {
		svg {
			display: inline-block;
		}
	}
}

.options-as-list {
	position: absolute;
	list-style-type: none;
	background: #ffffff;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	opacity: 0;
	z-index: 0;
	padding-inline-start: 0;
	transition: all 0.5s ease;

	li {
		width: 100%;
		padding: 8px 16px;
		@include font14-light(400);
		line-height: 24px;
		transition-duration: 0.5s;

		&:hover,
		&.active {
			background: #fdf9f1;
			font-weight: 500;
			cursor: pointer;
		}
	}

	&.show {
		width: 185px;
		top: 8px;
		left: 20px;
		z-index: 10;
		opacity: 1;
		box-shadow: 0 0 50px #171f461a;
		max-height: 600px;
		overflow: auto;
	}

	&.hide {
		display: none;
	}

	&.reverse {
		top: auto;
		bottom: 100%;
	}

	&.remuneration-list {
		width: 448px;
		top: auto;
		bottom: 100%;
		left: 0;

		li {
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-bottom: 1px solid $borderColorPattern1;
			width: 93%;
			padding: 8px 0;
			margin-left: 16px;

			&:first-child {
				width: 100%;
				margin: 0;
				padding: 15px 16px 8px 15px;
			}

			&:hover {
				background: transparent !important;
			}
		}
	}

	&.notice-periods {
		left: 0;
	}
}

.section-title {
	@include section-title;
}

.title {
	font-size: 18px;
	line-height: 26px;
	font-weight: 500;
	color: #141414;
}

.grid {
	&.row {
		display: flex;
		flex-direction: row;
		width: 100%;
		gap: 24px;
		margin: 16px 0;

		.col {
			display: flex;
			flex-direction: column;
			flex-grow: 1;
		}
	}

	&.row-with-single-col-with-half-size {
		.col {
			width: 50%;
			max-width: 50%;
			padding-right: 14px;
		}
	}
}

.input-wrapper {
	position: relative;

	label {
		font-size: 14px;
		line-height: 24px;
		font-weight: 500;
		color: #141414;
		margin-bottom: 4px;
		display: flex;
		align-content: center;

		.label-input {
			flex-grow: 1;
			padding-bottom: 0;

			input {
				border: none;
				padding: 0 10px;
				height: 25px;
			}

			&.invalid-input {
				padding-bottom: 30px;
			}
		}
	}

	.has-error input {
		border: 1px solid $red !important;
	}

	.error-text {
		position: relative !important;
		bottom: -5px !important;
		top: 10px;

		&:before {
			content: "";
			display: block;
			width: 0;
			height: 0;
			border-left: 8px solid transparent !important;
			border-right: 8px solid transparent !important;
			border-bottom: 8px solid $errorColor !important;
			border-top: 0 solid $errorColor !important;
			position: absolute;
			bottom: 25px !important;
			left: 2px;
		}
	}

	.invalid-input {
		display: flex;
		flex-direction: column;

		.error-text {
			bottom: -5px !important;
		}
	}
}

.text-area-custom {
	height: auto;
	border: 1px solid #dedede;
	padding: 10px 16px;
}

.font-bold {
	font-weight: 500 !important;
}

.font-normal {
	font-weight: 400;
}

.value-wrapper {
	position: relative;
	display: flex;

	&:hover {
		&:after {
			position: absolute;
			content: attr(data-tooltip);
			bottom: -20px;
			right: -50px;
			background-color: rgba(43, 43, 43, 0.8);
			color: #ffffff;
			padding: 5px 10px;
			font-size: 13px;
		}
	}
}

.error-messages {
	display: flex;
	justify-content: space-between;
	width: 100%;
	padding: 20px;
	border: 1px solid $red;
	color: $red;
	font-size: 16px;
	font-weight: 400;
	line-height: 22px;

	svg {
		&:hover {
			cursor: pointer;
		}
	}
}

.notification {
	.title {
		color: #ffffff !important;
	}
}

.project-header {
	margin: 32px 0;
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 21px;

	.icon > div {
		cursor: pointer;
	}

	&.text-only {
		@include breakpoint(xxs) {
			gap: 0;
		}

		.header-info {
			@include breakpoint(xxs) {
				text-align: center;
				width: 100%;
				display: inline-block;
			}
		}
	}

	.header-info {
		$mobileHeight: 48px;

		display: flex;
		align-items: center;
		gap: 16px;
		font-size: 18px;
		line-height: 26px;

		@include breakpoint(xs) {
			flex-direction: column;
			grid-gap: 0;
			gap: 0;
			align-items: flex-start;
		}

		.company {
			display: flex;
			align-items: center;
			gap: 12px;
			font-weight: 500;
			color: $blackAlt;

			img {
				border-radius: 50%;
			}

			span {
				border-right: 1px solid $borderColorPattern3;
				padding-right: 16px;

				@include breakpoint(xs) {
					font-size: 16px;
					border-right: none;
				}
			}

			@include breakpoint(xs) {
				& > .logo {
					margin-bottom: -22px;
				}
			}
		}

		.project-title {
			color: #676767;
			line-height: 22px;

			@include breakpoint(xs) {
				font-size: 14px;
				margin-left: 60px;
			}
		}
	}
}

.candidate-summary-card,
.market-insight-card-wrapper,
.insight-report,
.fit-to-brief,
.document-item,
.candidate-card,
.actions {
	.edit-icon {
		visibility: hidden;
	}

	:hover * {
		visibility: visible !important;
	}
}

blockquote {
	border-left: 5px solid $blackYellow;
	margin: 10px 0;
	padding: 10px 20px;
}

pre {
	code {
		display: block;
		padding: 1rem 1.5rem;
		white-space: pre;
	}
}

code {
	padding: 0.2rem 0.5rem;
	margin: 0 0.2rem;
	font-size: 90%;
	white-space: nowrap;
	background: #f1f1f1;
	border: 1px solid #e1e1e1;
	border-radius: 4px;
}

img[src$="empty_logo.png"] {
	border: 1px solid $borderColorPattern2;
}

.DayPickerInput {
	input {
		@include input-design();
	}
}

.add-icon-with-label {
	display: flex;
	justify-content: flex-end;
	align-items: center;

	span {
		svg {
			margin-left: 12px;
		}
	}
}

.text-input-with-icon {
	display: flex;
	flex-direction: row;
	align-items: center;

	.trash-icon {
		margin-left: 16px;
	}

	.input {
		flex-grow: 1;
	}
}

.circular {
	&--portrait {
		position: relative;
		overflow: hidden;
		border-radius: 50%;

		img {
			width: 100%;
			min-width: 135px;
			height: auto;
			margin-top: -50px;
		}
	}

	&--landscape {
		display: inline-block;
		position: relative;
		overflow: hidden;
		border-radius: 50%;

		img {
			width: auto;
			height: 100%;
			min-height: 135px;
			margin-left: -50px;
		}
	}
}

.register-form {
	.error-text {
		bottom: -17px;
	}
}
